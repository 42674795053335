import React, { useContext, useEffect, useState } from 'react'
import { ConteoSimpatizantesTotal } from '../components/ConteoSimpatizantesTotal';
import CountUp from 'react-countup';
import { AuthContext } from '../context/AuthContext';
import fondo from '../assets/simpatizantes.jpeg'

export const Home1Screen = () => {
    const [diasEleccion, setDiasEleccion] = useState(0);
    const [cumples, setCumples] = useState([]);
    const {URL}  = useContext(AuthContext);

    const obtenerDiasEleccion = () => {
        const actual = new Date().getTime();
        const eleccion = new Date('Jun 2 2024').getTime();
        let diferencia = eleccion - actual;
        diferencia = parseInt((diferencia/(1000*60*60*24)));
        setDiasEleccion(diferencia);


    }

    const obtenerCumple = () => {
        fetch(URL+'/simpatizantes.php?funcion=simpatizantesCumple')
        .then(response => response.json())
        .then(data => {
            console.log('cumples', data);
            setCumples(data);
        })
    }


    useEffect(() => {
        obtenerDiasEleccion();
        obtenerCumple();
    }, [])
    
    const FotoSimpatizante =({idSimpatizante}) => {
        return (
            <img 
            style={{
                marginTop:5,
                marginLeft:10,
                width:'70%',
                height:'90%',
                //position:'absolute'

            }}
            src={'http://raosari.dyndns.info:8080/decidechih/simpatizantes/'+idSimpatizante+'.jpg'}
        />
        )
    }

  return (
    <div
        style={{
            color:'white',
            display:'flex',
            flex:1,
            flexDirection:'row',
            backgroundColor:'black',
      //      backgroundImage:`url(${fondo})`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            backgroundPositionY:0,
            backgroundColor:'black',
            backgroundPosition:'center',
    
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                backgroundColor:'transparent'
            }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    color:'black',
                    backgroundColor:'white',
                    opacity:.75,
                    maxHeight:120,
                    marginTop:20,
                    marginLeft:20,
                    borderRadius:20,
                    padding:20
                }}
            >
                <div
                    style={{
                        fontSize:35,
                        color:'green',
                        fontWeight:'bold'
                    }}
                >
                    <CountUp end={diasEleccion} />
                </div>
                <div style={{fontSize:20}}>
                    Días Para La Elección
                </div>
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    color:'black',
                    backgroundColor:'white',
                    opacity:.75,
                    maxHeight:120,
                    marginTop:20,
                    marginLeft:20,
                    borderRadius:20,
                    padding:20,
                    fontSize:20

                }}
            >
                <div>
                    Red 
                </div>
                <div
                    style={{
                        fontSize:35,
                        display:'flex',
                        justifyContent:'center'
                    }}
                >
                    <ConteoSimpatizantesTotal tamFont="25" etiqueta="" />
                </div>
            </div>
        </div> 
        <div
            style={{
                backgroundColor:'transparent',
                marginLeft:50
            }}
        >
            {
                cumples?.map((cumple) => (
                    
                    <div
                    style={{
                        display:'flex',
                        flexDirection:'column',
                        color:'black',
                        backgroundColor:'white',
                        opacity:.75,
                        maxHeight:130,
                        marginTop:20,
                        marginLeft:20,
                        borderRadius:20,
                        padding:20
    
                    }}
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'row'
                        }}
                    >
                        <div
                            style={{
                                display:'flex',
                                flex:20
                            }}

                        >
                            {/* {cumple.Simpatizantes_IdSimpatizante} */}
                            <FotoSimpatizante idSimpatizante={cumple.Simpatizantes_IdSimpatizante} />
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flex:80,
                                flexDirection:'column'
                            }}
                        >
                            <div style={{fontSize:20, color:'green'}}>
                                {cumple.Simpatizantes_Nombre+' '+cumple.Simpatizantes_ApellidoPaterno+' '+cumple.Simpatizantes_ApellidoMaterno}
                            </div>
                            <div>
                                {cumple.Municipios_Nombre}
                            </div>
                            <div>
                                {cumple.FechaNacimiento}
                            </div>
                            <div>
                                Cumple Años!!!
                            </div>
                        </div>

                    </div>                    

                </div>
    
                ))
            }
        </div>      
    </div>
  )
}
