import React, { createContext, useEffect, useState } from 'react'
import { CardConteoSimpatizantesMpio } from './CardConteoSimpatizantesMpio';
import { SimpatizantesUsuario } from './SimpatizantesUsuario';

export const SimpatizantesContext = createContext();

export const SimpatizantesMunicipio = () => {

    const [simpatizantesMunicipio, setSimpatizantesMunicipio] = useState([])
    const [municipioSeleccionado, setMunicipioSeleccionado] = useState();
        useEffect(() => {
        obtenerSimpatizantesMpio();
    }, [])
    
    const obtenerSimpatizantesMpio = () => {
        fetch('http://mariolegarreta.ddns.net/decidechih/servicios/simpatizantes.php?funcion=conteoSimpatizantesMunicipio')
        .then(response => response.json())    
        .then(data => {
            console.log('simpatizantes mpio', data)
            setSimpatizantesMunicipio(data);
        })
        .catch(error => {
            console.log('Ocurrio un error', error)
        })
    
    }
    
if(simpatizantesMunicipio.length) {
  return (
    <SimpatizantesContext.Provider value={{municipioSeleccionado, setMunicipioSeleccionado}}>
    <div
        style={{
            backgroundColor:'transparent',
            color:'white',
            borderRadius:10,
            display:'flex',
            flexDirection:'row',
            opacity:.75
        }}
    >
        <div
        >
        {
            
            simpatizantesMunicipio?.map(conteoMunicipio => (
                <div
                >
                <CardConteoSimpatizantesMpio  municipio = {conteoMunicipio} />
                </div>
            ))
        }

        </div>
        <div
            style={{
                marginLeft:20
            }}
        >
            <SimpatizantesUsuario />

        </div>
    </div>
    </SimpatizantesContext.Provider>
  )
    } else {
        return <></>
    }
}
