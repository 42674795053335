import React, { useContext } from 'react'
import fondo from '../assets/fondoCard1.jpeg'
import sinergia from '../assets/sinergia2024-1.png'
import banda from '../assets/banda.png'
import muestra from '../assets/avatar.png'
import { maxWidth } from '@mui/system'
import ListItemText from '@mui/material/ListItemText';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { CameraAlt } from '@mui/icons-material'
import { AuthContext } from '../context/AuthContext'
import { SubirFotoIntegranteComite } from './SubirFotoIntegranteComite'
export const TarjetaIntegrante = ({integrante}) => {
    const {estadoModalFotografiaMilitante, setEstadoModalFotografiaMilitante, integranteSeleccionado, setIntegranteSeleccionado} = useContext(AuthContext);

//    console.log('Integrante recibido', integrante)

const tomarFoto = () => {
    setIntegranteSeleccionado(integrante);
    setEstadoModalFotografiaMilitante(true);
    console.log('click en subir foto');
}

  return (
  <>
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            // flex:1,
            width:'25vw',
            padding:0,
            margin:0,
            backgroundImage:`url(${fondo})`,
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            backgroundPositionY:0,
            backgroundColor:'red',
            backgroundPosition:'center',
            
            // width:'100%',
            height:'100%',
            borderRadius:10,
            marginBottom:0,
            marginTop:0

        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'row',

            }}
        >
        <div
            style={{
                display:'flex',
                flexDirection:'row',
//                flex:.5,
                flexWrap:'nowrap',
                backgroundColor:'transparent', 
                justifyContent:'flex-end'
            }}
        >

        </div>
        <div
            style={{
                display:'flex',
                flex:4,
                backgroundColor:'transparent'
     //           flexDirection:'column'
            }}
        ></div>
        <div
            style={{
                display:'flex',
                flex:6,
                flexDirection:'column',
                backgroundColor:'transparent'
            }}
        >
            <div
                style={{
                    position:'relative',
                    display:'flex',
                    flex:1,
                    
                    marginTop:85,
                    marginRight:5,
                    fontSize:14,
                    fontWeight:'bold',
                    justifyContent:'flex-end',
                    backgroundColor:'transparent'
                }}
            >
            {integrante.Nombre}
            </div>
            <div>
                <img 
                    style={{
                        marginTop:0,
                        marginLeft:0,
                        width:'100%',
                        height:'70%',
                        //position:'absolute'

                    }}
                    src={banda}
                />
            </div>
            <div
                style={{
                    fontWeight:'bold'
                }}
            >
                {integrante.Cargos_Descripcion}
            </div>
            <div
                style={{
                    fontWeight:'bold',
                    display:'flex',
                    justifyContent:'flex-start',
                    marginTop:'5%',
                    backgroundColor:'transparent',
                    fontSize:12,
                    marginLeft:5
                }}
            >
                    <PhoneAndroidIcon style={{marginRight:10, marginLeft:2}} />  {integrante.Telefono}
            </div>
            <div
                style={{
                    fontWeight:'bold',
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'flex_start',
                    alignItems:'flex-start',
                    fontSize:10,
                    textAlign:'left',
                    marginLeft:5,
                    backgroundColor:'transparent'
                }}
            >
                <HomeRoundedIcon style={{marginRight:10}} />  {integrante.Direccion}
            </div>
            <div
                style={{
                    fontWeight:'bold',
                    display:'flex',
                    justifyContent:'center',
                    fontSize:17,
                    fontWeight:'bold',
                    marginTop:'2%'
                }}
            >
                {integrante.Municipio}
            </div>
        </div>
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                width:'7%',
                height:'25vh',
                flexWrap:'nowrap',
                backgroundColor:'transparent', 
                justifyContent:'flex-end',
                alignItems:'flex-end',
                transform:"rotate(-7deg)",
                position:'absolute',
                backgroundImage: 'url('+integrante.foto+')',
                backgroundRepeat:'no-repeat',
                backgroundPosition:'center',
                backgroundSize:'cover',
                marginTop:'10vh',
                borderRadius:10
                
            }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    backgroundColor:'white',
                    position:'absolute',
                    width:'100%',
                    height:'15%',
                    opacity:.5

                }}
            >
            <img 
                style={{
                    marginTop:5,
                    marginLeft:10,
                    width:'70%',
                    height:'90%',
                    //position:'absolute'

                }}
                src={sinergia}
            />
            <div
                onClick={tomarFoto}
                style={{
                    backgroundColor:'transparent'
                }}
            >
            <CameraAlt />

            </div>
            </div>

        </div>
    </div>
</>                
  )
}
