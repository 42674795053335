import React, { useContext, useEffect } from 'react'
import { Mapa } from '../components/Mapa'
import Fade from '@mui/material/Fade';
import { AuthContext } from '../context/AuthContext';
import { DatosMunicipio } from '../components/DatosMunicipio';
import fondo from '../assets/FondoMapa.jpeg'
import { TablaResultadosPartido } from '../components/TablaResultadosPartido';
import { GraficaPartidoMunicipio } from '../components/GraficaPartidoMunicipio';
import { DatosMilitante } from '../components/DatosMilitante';
import { GraficaPartidoEstado } from '../components/GraficaPartidoEstado';
import { DatosSimpatizante } from '../components/DatosSimpatizante';

export const ResultadosScreen = () => {
const {
  mostrarFiltros, 
  setMostrarFiltros, 
  mostrarDatosCapa,
  idMunicipioSeleccionado, 
  setIdMunicipioSeleccionado, ambito} = useContext(AuthContext); 

useEffect(() => {
  setMostrarFiltros(true);

  return () => {
    setMostrarFiltros(false);
  }
}, [])


  return (
    <div
      style={{
        display:'flex',
        flexDirection:'row',
     //   backgroundImage:`url(${fondo})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPositionY:0,
        backgroundColor:'black',
        backgroundPosition:'center',

      }}
    >
      <div
          style={{
              borderRadius:20
          }}
      >
          <Fade>
              <Mapa />
          </Fade>
      </div>
      <div>
        {
          mostrarDatosCapa==='militante' &&
          <div
            style={{
              color:'white'
            }}
          >
          <DatosMilitante />
        </div>
        }
        {
          mostrarDatosCapa==='simpatizante' &&
          <div
            style={{
              color:'white'
            }}
          >
          <DatosSimpatizante />
        </div>
        }
        {
          mostrarDatosCapa==='municipio' &&
          <>
            <div
              style={{
                color:'white',
                display:'flex',
                flexWrap:'nowrap',
              }}
            
            >
              <DatosMunicipio />
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'row'
              }}
            >
              <div
                style={{
                  color:'white',
                  display:'flex',
                  flexWrap:'nowrap',
                  width:300,
                  backgroundColor:'transparent',
                }}
              
              >
                <TablaResultadosPartido />
              </div>
              <div
                style={{
                  color:'white',
                  display:'flex',
                  flexWrap:'nowrap',
                  width:300,
                  backgroundColor:'transparent',
                }}
              
              >
                <GraficaPartidoMunicipio />
              </div>
              {
                ambito == 9 && (
                <div
                  style={{
                    color:'white',
                    display:'flex',
                    flexWrap:'nowrap',
                    width:300,
                    backgroundColor:'transparent',
                    marginLeft:30
                  }}
                
                >
                  <GraficaPartidoEstado />
                </div>
                )
              }
            </div>
          </>
        }
      </div>        
    </div>
  )
}
