import React, { useContext, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { AuthContext } from '../context/AuthContext';

ChartJS.register(ArcElement, Tooltip, Legend);



export function GraficaPartidoMunicipio() {



    const { datosPartido, setDatosPartido, URL} = useContext(AuthContext); 
    console.log('Datos Partido', datosPartido)
    const [datos, setDatos] = useState([]);
    const [colores, setColores] = useState([]);
    const [datos2, setDatos2] = useState(
          {
            labels: datosPartido?.tablaDatos?.map((resultado) => (resultado.Partidos_Partido)),
            datasets: [
              {
                label: '%',
                data: datosPartido?.tablaDatos?.map((resultado) => parseInt(resultado.CasillasResultadosDetalle_Votos)),
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
              },
            ]}
    )
    console.log('datos partido en grafica', datosPartido);

    useEffect(() => {
        setDatos2(
            {
                labels: datosPartido?.tablaDatos?.map((resultado) => (resultado.Partidos_Partido)),
                datasets: [
                  {
                    label: '%',
                    data: datosPartido?.tablaDatos?.map((resultado) => parseInt(resultado.CasillasResultadosDetalle_Votos)),
                    backgroundColor: datosPartido?.tablaDatos?.map((resultado) => '#'+(resultado.Color1)),
                    borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                  },
                ]}            
        )

    }, [datosPartido])
    
    useEffect(() => {
        // console.log('datos en useeffect de datos', datos)
        //  const data = {
        //     labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        //     datasets: [
        //       {
        //         label: '%',
        //         data: {datos},
        //         backgroundColor: [
        //           'rgba(255, 99, 132, 0.2)',
        //           'rgba(54, 162, 235, 0.2)',
        //           'rgba(255, 206, 86, 0.2)',
        //           'rgba(75, 192, 192, 0.2)',
        //           'rgba(153, 102, 255, 0.2)',
        //           'rgba(255, 159, 64, 0.2)',
        //           'rgba(255, 159, 64, 0.2)',
        //         ],
        //         borderColor: [
        //           'rgba(255, 99, 132, 1)',
        //           'rgba(54, 162, 235, 1)',
        //           'rgba(255, 206, 86, 1)',
        //           'rgba(75, 192, 192, 1)',
        //           'rgba(153, 102, 255, 1)',
        //           'rgba(255, 159, 64, 1)',
        //           'rgba(255, 159, 64, 1)',
        //         ],
        //         borderWidth: 1,
        //       },
        //     ],
        //   };

        //   setDatos2(data)
      
    }, [datos])

    useEffect(() => {
        console.log('datos2',datos2)
    }, [datos2])
    
if(datos2?.datasets) {    
  return (
    <div
    style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        flexWrap:'nowrap',
        flex:1,
        margin:10,
        marginTop:10,
        backgroundColor:'white',
        opacity:.85,
        borderRadius:10,
        color:'black',
        minHeight:100,
        marginLeft:20,
        padding:0
    }}
    >
      <Pie data={datos2}
          
       />
  </div>

  )
  } else {
    return (
      <></>
    )
  }
}
