import React, { useEffect } from 'react'
import { ConteoMilitantes } from './ConteoMilitantes';
import { IntegrantesComiteMunicipio } from './IntegrantesComiteMunicipio';
import Fade from '@mui/material/Fade';
import { ConteoSimpatizantes } from './ConteoSimpatizantes';

export const DatosMunicipio = () => {
  return (

    <div
        style={{
            display:'flex',
            flexDirection:'row'
        }}
    >
            {/* <div><IntegrantesComiteMunicipio /></div> */}
            <div><ConteoMilitantes /></div>
            <div><ConteoSimpatizantes /></div>
    </div>
  )
}
