import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import { Alert, Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Snackbar, Switch } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import { CheckBox } from '@mui/icons-material';
export default function AgregarUsuario(props) {
    const {modalAgregarUsuario, setModalAgregarUsuario, URL, obtenerUsuarios} = React.useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [nombre, setNombre] = useState('');
    const [nombreError, setNombreError] = useState(true);
    const [usernameError, setUsernameError] = useState(true);
    const [apellidoPaternoError, setApellidoPaternoError] = useState(true);
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [errorPassword, setErrorPassword] = useState(true);
    const [datosCompletos, setdatosCompletos] = useState(true);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [divisiones, setDivisiones] = useState([]);
    const [divisionSeleccionada, setDivisionSeleccionada] = useState([]);
    const [generaOpinion, setGeneraOpinion] = useState(false);
    const [veTodasOpiniones, setveTodasOpiniones] = useState(false);
    console.log('props recibidas en formulario', props);

    
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setModalAgregarUsuario(false);
    setNombre('');
    setApellidoPaterno('');
    setApellidoMaterno('');
    setUsername('');
    setPassword('');
    setPassword1('');
    setModalAgregarUsuario(false);
  };
  useEffect(() => {
    validarFormulario();
  }, [username,nombre,apellidoPaterno,password,password1])
  

  const validarFormulario = () => {
    console.log('logitud de nombre:',nombre.length)
    nombre.length==0 ? setNombreError(true):setNombreError(false);
    username.length==0 ? setUsernameError(true):setUsernameError(false);
    apellidoPaterno.length==0 ? setApellidoPaternoError(true):setApellidoPaternoError(false);

    if(password.length>0 && (password!==password1)) {
        setErrorPassword(true);
    } else {
        setErrorPassword(false);
    }

    if(nombre.length==0 || apellidoPaterno.length==0 || username.length==0 || password.length==0) {
        console.log('datos incompletos',nombre,apellidoPaterno,username,password);
        setdatosCompletos(true);
    } else {
        console.log('guardar registro');
        setdatosCompletos(false);
    }
  }

  const handleCloseSuccess = () => {
    setModalAgregarUsuario(false);
    setNombre('');
    setApellidoPaterno('');
    setApellidoMaterno('');
    setUsername('');
    setPassword('');
    setPassword1('');
  }

  const handleGuardar = () => {
        console.log('guardar registro');
        fetch(URL+'/usuarios.php?funcion=agregarUsuario&nombre='+nombre+'&apaterno='+apellidoPaterno+'&amaterno='+apellidoMaterno+'&password='+password+'&username='+username+'&generaOpinion='+generaOpinion+'&veTodasOpiniones='+veTodasOpiniones)
        .then((response) => response.json())
        .then(data => {
            console.log('respuesta de guardado',data);
            if(data=="Registro guardado") {
                console.log('Registro guardado');
                setOpenSuccess(true);
                setModalAgregarUsuario(false);
                obtenerUsuarios();
            } else {
                console.log('Error al guardar');
            }
        })
        .catch(error => {
            console.log('ocurrio un error',error);
        })

  }

  useEffect(() => {
    console.log(generaOpinion)
  
  }, [generaOpinion])
  
  useEffect(() => {
    console.log(veTodasOpiniones)
  
  }, [veTodasOpiniones])
  

  return (
    <div style={{borderRadius:20}}>
      <Dialog style={{borderRadius:20}} open={modalAgregarUsuario} onClose={handleClose}>
        <DialogTitle>Agregar Usuario</DialogTitle>
        <DialogContent component="form">
        <Box
            style={{
                display:'flex',
                flexDirection:'row'
            }}
        >
            <TextField
                error={usernameError}
                autoFocus
                margin="dense"
                id="Username"
                label="Username"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => {setUsername(e.target.value)}}
                value={username}
            />
            <TextField
                style={{
                    marginLeft:10
                }}
                error={errorPassword}
                margin="dense"
                id="Password"
                label="Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={(e) => {setPassword(e.target.value)}}
                value={password}
            />
            <TextField
                error={errorPassword}

                style={{
                    marginLeft:10
                }}
                margin="dense"
                id="Password1"
                label="Confirme Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={(e) => {setPassword1(e.target.value)}}
                value={password1}
            />


        </Box>
        <TextField
            error={nombreError}
            required
            margin="dense"
            id="Nombre"
            label="Nombre"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {setNombre(e.target.value)}}
            value={nombre}
          />
          <Box
            style={{
                display:'flex',
                flexDirection:'row',
            }}
          >
            <TextField
                error={apellidoPaternoError}
                required
                margin="dense"
                id="APaterno"
                label="Apellido Paterno"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => {setApellidoPaterno(e.target.value)}}
                value={apellidoPaterno}
            />
            <TextField
                style={{marginLeft:10}}
                margin="dense"
                id="AMaterno"
                label="Apellido Materno"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => {setApellidoMaterno(e.target.value)}}
                value={apellidoMaterno}
            />

          </Box>
          <Box
            style={{
                display:'flex',
                flexDirection:'row',
                marginTop:10
            }}
          >
            <div
            >Guarda opinion</div>
            <Switch 
              onChange={(e) => setGeneraOpinion(e.target.checked)}
            />
              
            <div
                          style={{
                            marginLeft:20
                          }}
            
            >Ve todas las opioniones</div>
            <Switch 
              onChange={(e) => setveTodasOpiniones(e.target.checked)}
              label="Guarda opinión"

            />

            </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button disabled={datosCompletos} onClick={handleGuardar}>Guardar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSuccess} onClose={handleCloseSuccess} autoHideDuration={3000} >
        <Alert  severity="success" sx={{ width: '100%' }}>
            Registro guardado!
        </Alert>
      </Snackbar>
    </div>
  );
}
