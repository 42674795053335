import { Image } from '@mui/icons-material';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import LazyLoad from 'react-lazy-load';
import { SubirFotoIntegranteComite } from '../components/SubirFotoIntegranteComite';
import { TarjetaIntegrante } from '../components/TarjetaIntegrante';
import { AuthContext } from '../context/AuthContext'



export const IntegrantesComite = () => {
    const {mostrarOpcionesComite, setMostrarOpcionesComite, URL, filtroIntegrantes, estadoModalFotografiaMilitante} = useContext(AuthContext)
    const [integrantes, setIntegrantes] = useState([]);
    const [pruebas, setPruebas] = useState([]);

    useEffect(() => {
      console.log('filtrando');
      const timeOutId = setTimeout(() => obtenerIntegrantes(), 1000);
      return () => clearTimeout(timeOutId);
    }, [filtroIntegrantes])

    useEffect(() => {
      obtenerIntegrantes();
    }, [estadoModalFotografiaMilitante])
    
    

    useEffect(() => {
        setMostrarOpcionesComite(true);
        obtenerIntegrantes();
        obtenerPruebas();
        return () => {
          setMostrarOpcionesComite(false)
        }
      }, [])

      const obtenerIntegrantes = () => {
        fetch(URL+'/integrantes.comite.php?filtro='+filtroIntegrantes)
        .then(response => response.json())
        .then(data => {
            setIntegrantes(data);
            console.log('Datos de integrantes', data);
        })
      }

      const obtenerPruebas = () => {
        fetch('https://dummyjson.com/products')
        .then(response => response.json())
        .then(data => {
            setPruebas(data);
            console.log('Datos de prueba', data);
        })

        const filtrarIntegrantes = () => {

        }

      }

if(pruebas?.products) {
  return (
    <>
    <div
        style={{
            color:'white',
            padding:20,
            borderRadius:20,
            display:'flex',
            flexDirection:'row',
            flexWrap: 'wrap',
            backgroundColor:'black'
            
        }}
    >
    {/* <Suspense fallback={<div>Loading...</div>}> */}
      {
        integrantes.map(integrante => (
//          console.log(prueba)
          <div
            key={integrante.IdIntegrante}
            style={{
              display:'flex',
              color:'black',
              backgroundColor:'black',
              width:'25vw',
              height:'40vh',
              borderRadius:20,
              padding:0,
              margin:10,
              marginRight:25,
              marginBottom:15,
              marginTop:15,
              justifyContent:'space-between',
              
            }}
          >
            <LazyLoad>
            {/* {prueba.title} */}
              <TarjetaIntegrante
                integrante={integrante} />
            </LazyLoad>  
      </div>
        ))
      }
      {/* </Suspense> */}
    </div>
    <SubirFotoIntegranteComite/>
    </>

  )
    } else {
      return (
        <div style={{color:'white'}}>Sin datos</div>
      )
    }
}
