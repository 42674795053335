import React, {useContext, useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AuthContext } from '../context/AuthContext';
import { TextareaAutosize } from '@mui/material';

export default function AgregaOpinion({ObtenerSimpatizantes, simpatizanteSeleccionado, setActualizaOpiniones, actualizaOpiniones}) {
    const opinionRef = useRef();
    const {modalAgregaOpinion, setModalAgregaOpinion, usuario} = useContext(AuthContext);
    console.log('modal agrega opinion', modalAgregaOpinion)

  const handleClose = () => {
    setModalAgregaOpinion(false);
  };

  const handleGuardar =() => {
    console.log(opinionRef.current.value, simpatizanteSeleccionado, usuario)
    fetch('http://mariolegarreta.ddns.net/decidechih/servicios/simpatizantes.php?funcion=agregaOpinion&idUsuario='+usuario.Usuarios_IdUsuario+'&idSimpatizante='+simpatizanteSeleccionado.Simpatizantes_IdSimpatizante+'&opinion='+opinionRef.current.value)
    .then(response => {
        setModalAgregaOpinion(false);
        setActualizaOpiniones(actualizaOpiniones+1);
//        ObtenerSimpatizantes();
    })        
    .catch(error => {
        console.error('Ocurrio un error');
    })
  }

  return (
    <div>
      <Dialog
        open={modalAgregaOpinion}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Agregar Opinión"}
          <div
            >
                {simpatizanteSeleccionado?.Simpatizantes_Nombre+' '+simpatizanteSeleccionado?.Simpatizantes_ApellidoPaterno}
            </div>

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextareaAutosize 
                ref={opinionRef}
                autoFocus={true}
                aria-label="minimum height"
                minRows={5}
                placeholder="Opinión"
                style={{ width: 400, borderRadius:10, padding:10 }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button onClick={handleGuardar} autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}