import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect, useReducer } from 'react';
import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import validacion from '../hooks/useValidacion';
import { AuthContext } from '../context/AuthContext';
import { estadoModalSimpatizante } from '../screens/SimpatizantesScreen';
import { CameraAlt } from '@mui/icons-material'
import avatar from '../assets/avatar.png'
import { useRef } from 'react';
import { ImageSearch, Input } from '@mui/icons-material'

const arregloCampos = [
  {campo:'nombre',error:true},
  {campo:'apellidoPaterno',error:true},
  {campo:'apellidoMaterno',error:true},
  {campo:'idMunicipio',error:true},
  {campo:'seccion',error:true},
  {campo:'direccion',error:true},
]
export default function AgregarSimpatizante(props) {
  const uploadInputRef = useRef(null);
  const imgMilitante = useRef(null);

 const ObtenerSimpatizantes = React.useContext(estadoModalSimpatizante);
  const {usuario, modalAgregarSimpatizante, setModalAgregarSimpatizante,URL, idSimpatizanteEdicion, setIdSimpatizanteEdicion} = React.useContext(AuthContext);
    const [municipios, setMunicipios] = useState([]);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [datosCompletos, setdatosCompletos] = useState(true);
    const [nombre, setNombre] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [direccion, setDireccion] = useState('');
    const [errores, setErrores] = useState(arregloCampos);
    const [idMunicipio, setIdMunicipio] = useState(0);
    const [secciones, setSecciones] = useState([]);
    const [idSeccion, setIdSeccion] = useState(0);
    const [celular, setCelular] = useState('');
    const [redesSociales, setRedesSociales] = useState('');
    const [organizaciones, setOrganizaciones] = useState([]);
    const [idOrganizacion, setIdOrganizacion] = useState(0);
    const [file, setFile] = useState();
    const [fechaNacimiento, setFechaNacimiento] = useState();

  const handleClickOpen = () => {
  };

  const handleClose = () => {
    setModalAgregarSimpatizante(false);
  };

  useEffect(() => {
    validarFormulario();
  }, [nombre])

  useEffect(() => {
    if(idSimpatizanteEdicion>0) {
      setModalAgregarSimpatizante(true);
      obtenerDatosEdicion();
    }
  
    return () => {
      setIdSimpatizanteEdicion();
    }
  }, [idSimpatizanteEdicion])
  
  const obtenerDatosEdicion = () => {
    fetch('http://mariolegarreta.ddns.net/decidechih/servicios/simpatizantes.php?funcion=simpatizante&idsimpatizante='+idSimpatizanteEdicion)
    .then(response => response.json())
    .then(datos => {
      console.log('datos de simpatizante para edicion', datos);
    })
    .catch(error => {
      console.error('Ocurrio un error', error);
    })
  }
  

  const validarFormulario = () => {
    console.log('Validando formulario');
    if(nombre.length==0) {
      setdatosCompletos(true);
    } else {
      setdatosCompletos(false);
    }
  }

  const SeleccionImagen = (event) => {
    setFile({file:event.target.files[0]});
    console.log('selecciono imagen',event);
    var reader = new FileReader();
    reader.onload = function(){
        imgMilitante.current.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
};

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    ObtenerSimpatizantes();

  }

  const tomarFoto = () => {
  
  }

  const handleGuardar = () => {
    //    console.log('guardar registro');
    var data = new FormData();
    data.append("data", uploadInputRef.current.files[0]);     
        fetch(URL+'/simpatizantes.php?funcion=agregaSimpatizante&nombre='+nombre+'&apaterno='+apellidoPaterno+'&amaterno='+apellidoMaterno+'&direccion='+direccion+'&idMunicipio='+idMunicipio+'&idSeccion='+idSeccion+'&idUsuario='+usuario.Usuarios_IdUsuario+'&celular='+celular+'&idOrganizacion='+idOrganizacion+'&redesSociales='+redesSociales+'&fechaNacimiento='+fechaNacimiento, { 
          method:'POST',
          body:data
      })
        .then((response) => response.json())
        .then(data => {
            console.log('respuesta de guardado',data);
            if(data=="1") {
                console.log('Registro guardado');
                setOpenSuccess(true);
                setModalAgregarSimpatizante(false);
            } else {
                console.log('Error al guardar');
            }
        })
        .catch(error => {
            console.log('ocurrio un error',error);
        })

  }


  useEffect(() => {
    obtenerMunicipios();
    obtenerOrganizaciones();
  }, [])

  const obtenerMunicipios = () => {
    fetch(URL+'/municipios2.php?funcion=municipios')
    .then(response => response.json())
    .then(data => {
      console.log('municipios', data);
      setMunicipios(data);
    })
    .catch(error => {
      console.log('Ocurrio un error', error)
    })
  }

  const obtenerOrganizaciones = () => {
    fetch(URL+'/organizaciones.php?funcion=organizaciones')
    .then(response => response.json())
    .then(data => {
      console.log('municipios', data);
      setOrganizaciones(data);
    })
    .catch(error => {
      console.log('Ocurrio un error', error)
    })
  }

  useEffect(() => {
    obtenerSecciones();
  
  }, [idMunicipio])
  

  const obtenerSecciones = () => {
    fetch(URL+'/municipios2.php?funcion=municipioSecciones&municipio='+idMunicipio)
    .then(response => response.json())
    .then(data => {
      console.log('secciones', data);
      setSecciones(data);
    })
    .catch(error => {
      console.log('Ocurrio un error', error)
    })

  }

  
  const handleFormValidation = (campo, error) =>{
    console.log('campo error',campo,error);
        setErrores(current =>
          current.map(obj => {
            if (obj.campo === campo) {
              return {...obj, campo: campo, error: error};
            }
    
            return obj;
          }),
        );

  }

  useEffect(() => {
    console.log('errores cambio', errores);
    let errorFormulario = false;
    errores.map(error => {
      if(error.error==true) {
        errorFormulario =true;
      }

    });
    setdatosCompletos(errorFormulario);

    
    
  }, [errores])

  useEffect(() => {
    handleFormValidation('nombre', validacion('text', nombre, 1));
    handleFormValidation('apellidoPaterno', validacion('text', apellidoPaterno, 1));
    handleFormValidation('apellidoMaterno', validacion('text', apellidoMaterno, 1));
    handleFormValidation('direccion', validacion('text', direccion, 1));
    handleFormValidation('idMunicipio', validacion('number', idMunicipio, 1));
    handleFormValidation('seccion', validacion('number', idSeccion, 1));
    handleFormValidation('celular', validacion('number', celular, 1));
  
  }, [nombre, apellidoPaterno, apellidoMaterno, direccion, idMunicipio, idSeccion, celular])
  
  

  return (
    <div>
      <Dialog  
        maxWidth='sm'
        fullWidth
        open={modalAgregarSimpatizante} onClose={handleClose}>
        <DialogTitle>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:"space-between"
            }}
          >
            <div>
              Agregar Simpatizante
            </div>
            <div
                onClick={tomarFoto}
                style={{
                    backgroundColor:'transparent',
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'flex-end'
                }}
            >
              <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg' type="file" />
              <img style={{
                  width:150,
                  height:'auto'
              }} ref={imgMilitante} src={avatar} 
              onClick={() => uploadInputRef.current && uploadInputRef.current.click()}

              />
            </div>
          </div>


        </DialogTitle>
        <DialogContent component="form">
        <Box style={{display:'flex', flexDirection:'column'}}>
        {/* <div>{JSON.stringify(errores)}</div> */}
          <Box
            style={{
              display:'flex',
              flexDirection:'row'
            }}
          >
            <FormControl variant="standard" sx={{ mt: 2, display:'flex', flexDirection:'column', flex:1 }}>
              <TextField
                  autoFocus
                  margin="dense"
                  id="Nombre"
                  label="Nombre"
                  type="text"
                  error={validacion('text', nombre, 1)}
                  fullWidth
                  variant="standard"
                  onChange={(e) => {  setNombre(e.target.value.toUpperCase()); }}
                  value={nombre}
                />
              </FormControl>
            <FormControl variant="standard" sx={{ mt: 2, display:'flex', flexDirection:'column', flex:1, marginLeft:2 }}>
              <TextField
                  autoFocus
                  margin="dense"
                  id="ApellidoPaterno"
                  label="A. Paterno"
                  type="text"
                  error={validacion('text', apellidoPaterno, 1)}
                  fullWidth
                  variant="standard"
                  onChange={(e) => {  setApellidoPaterno(e.target.value.toUpperCase()); }}
                  value={apellidoPaterno}
                />
              </FormControl>
            <FormControl variant="standard" sx={{ mt: 2, display:'flex', flexDirection:'column', flex:1, marginLeft:2 }}>
              <TextField
                  autoFocus
                  margin="dense"
                  id="ApellidoMaterno"
                  label="A. Materno"
                  type="text"
                  error={validacion('text', apellidoMaterno, 1)}
                  fullWidth
                  variant="standard"
                  onChange={(e) => {  setApellidoMaterno(e.target.value.toUpperCase()); }}
                  value={apellidoMaterno}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl variant="standard" sx={{ mt: 0, display:'flex', flexDirection:'column', flex:1, marginLeft:0 }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="Direccion"
                    label="Dirección"
                    type="text"
                    error={validacion('text', direccion, 1)}
                    fullWidth
                    variant="standard"
                    onChange={(e) => {  setDireccion(e.target.value.toUpperCase()); }}
                    value={direccion}
                  />
                </FormControl>

            </Box>
            <Box>
              <FormControl variant="standard" sx={{ mt: 0, display:'flex', flexDirection:'column', flex:1, marginLeft:0 }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="FNacimiento"
                    label="F. Nacimiento"
                    type="date"
                    //error={validacion('text', direccion, 1)}
                    fullWidth
                    variant="standard"
                    onChange={(e) => {  setFechaNacimiento(e.target.value); }}
                    value={fechaNacimiento}
                    InputLabelProps={{
                      shrink:true
                    }}
                  />
                </FormControl>

            </Box>
            <Box>
              <FormControl variant="standard" sx={{ mt: 0, display:'flex', flexDirection:'column', flex:1, marginLeft:0 }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="Celular"
                    label="Celular"
                    type="number"
                    error={validacion('number', celular, 1)}
                    fullWidth
                    variant="standard"
                    onChange={(e) => {  setCelular(e.target.value.toUpperCase()); }}
                    value={celular}
                  />
                </FormControl>

            </Box>
            <FormControl variant="standard" sx={{ mt: 0,  marginLeft:0 }}>
              <InputLabel id="lblDivision">Municipio</InputLabel>
              <Select
                error={validacion('number', idMunicipio, 1)}
                
                onChange={(e) => {setIdMunicipio(e.target.value)}}
              >

                { municipios?.map((municipio) => (
                <MenuItem value={municipio.Municipios_IdMunicipio}>{municipio.Municipios_Nombre}</MenuItem>
              ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ mt: 0,  marginLeft:0 }}>
              <InputLabel id="lblDivision">Seccion</InputLabel>
              <Select
                error={validacion('number', idSeccion, 1)}
                
                onChange={(e) => {setIdSeccion(e.target.value)}}
              >

                { secciones?.map((seccion) => (
                <MenuItem value={seccion.Secciones_IdSeccion}>{seccion.Secciones_Seccion}</MenuItem>
              ))}
              </Select>
            </FormControl>
            <Box>
              <FormControl variant="standard" sx={{ mt: 0, display:'flex', flexDirection:'column', flex:1, marginLeft:0 }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="redesSociales"
                    label="Redes Sociales"
                    type="text"
             //       error={validacion('text', redesSociales, 1)}
                    fullWidth
                    variant="standard"
                    onChange={(e) => {  setRedesSociales(e.target.value.toUpperCase()); }}
                    value={redesSociales}
                  />
                </FormControl>
              </Box>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  flex:1
                }}
              >
                <FormControl variant="standard" sx={{ mt: 0,  marginLeft:0, display:'flex', flexDirection:'row', flex:1, width:300 }}>
              <InputLabel style={{width:300}} id="lblOrganizaciones">Organización</InputLabel>
              <Select
              fullWidth
                style={{
                  widht:300
                }}
                size='medium'
                //error={validacion('number', idMunicipio, 1)}
                
                onChange={(e) => {setIdOrganizacion(e.target.value)}}
              >

                { organizaciones?.map((organizacion) => (
                <MenuItem value={organizacion.IdOrganizacion}>{organizacion.Organizacion}</MenuItem>
              ))}
              </Select>
            </FormControl>

            </div>

          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button disabled={datosCompletos} onClick={handleGuardar}>Guardar</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSuccess} onClose={handleCloseSuccess} autoHideDuration={3000} >
        <Alert  severity="success" sx={{ width: '100%' }}>
            Registro guardado!
        </Alert>
      </Snackbar>
    </div>
  );
}
