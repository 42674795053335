import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect, useReducer } from 'react';
import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import validacion from '../hooks/useValidacion';
import { AuthContext } from '../context/AuthContext';
import { estadoModalMilitante } from '../screens/MilitantesScreen';
const arregloCampos = [
  {campo:'nombre',error:true},
  {campo:'apellidoPaterno',error:true},
  {campo:'apellidoMaterno',error:true},
  {campo:'idMunicipio',error:true},
  {campo:'seccion',error:true},
  {campo:'direccion',error:true},
]

export default function AgregarMilitante(props) {
 const ObtenerMilitantes = React.useContext(estadoModalMilitante);
  const {usuario, modalAgregarMilitante, setModalAgregarMilitante,URL} = React.useContext(AuthContext);
    const [municipios, setMunicipios] = useState([]);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [datosCompletos, setdatosCompletos] = useState(true);
    const [nombre, setNombre] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [direccion, setDireccion] = useState('');
    const [errores, setErrores] = useState(arregloCampos);
    const [idMunicipio, setIdMunicipio] = useState(0);
    const [secciones, setSecciones] = useState([]);
    const [idSeccion, setIdSeccion] = useState(0);
    const [calle, setCalle] = useState('');
    const [numeroExterior, setNumeroExterior] = useState('');
    const [numeroInterior, setNumeroInterior] = useState('');
    const [colonia, setColonia] = useState('');
  const handleClickOpen = () => {
  };

  const handleClose = () => {
    setModalAgregarMilitante(false);
  };

  useEffect(() => {
    validarFormulario();
  }, [nombre])
  

  const validarFormulario = () => {
    console.log('Validando formulario');
    if(nombre.length==0) {
      setdatosCompletos(true);
    } else {
      setdatosCompletos(false);
    }
  }

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    ObtenerMilitantes();

  }

  const handleGuardar = () => {
    //    console.log('guardar registro');
        fetch(URL+'/simpatizantes.php?funcion=agregaMilitante', {
          method:"POST",
          body: JSON.stringify({
            nombre,
            apellidoPaterno,
            apellidoMaterno,
            calle,
            numeroExterior,
            numeroInterior,
            colonia,
            idUsuario:usuario.Usuarios_IdUsuario,
            idMunicipio,
            idSeccion
          })
        })
        .then((response) => response.json())
        .then(data => {
            console.log('respuesta de guardado',data);
            if(data=="1") {
                console.log('Registro guardado');
                setOpenSuccess(true);
                setModalAgregarMilitante(false);
            } else {
                console.log('Error al guardar');
            }
        })
        .catch(error => {
            console.log('ocurrio un error',error);
        })

  }


  useEffect(() => {
    obtenerMunicipios();
  }, [])

  const obtenerMunicipios = () => {
    fetch(URL+'/municipios2.php?funcion=municipios')
    .then(response => response.json())
    .then(data => {
      console.log('municipios', data);
      setMunicipios(data);
    })
    .catch(error => {
      console.log('Ocurrio un error', error)
    })
  }

  useEffect(() => {
    obtenerSecciones();
  
  }, [idMunicipio])
  

  const obtenerSecciones = () => {
    fetch(URL+'/municipios2.php?funcion=municipioSecciones&municipio='+idMunicipio)
    .then(response => response.json())
    .then(data => {
      console.log('secciones', data);
      setSecciones(data);
    })
    .catch(error => {
      console.log('Ocurrio un error', error)
    })

  }

  
  const handleFormValidation = (campo, error) =>{
    console.log('campo error',campo,error);
        setErrores(current =>
          current.map(obj => {
            if (obj.campo === campo) {
              return {...obj, campo: campo, error: error};
            }
    
            return obj;
          }),
        );

  }
  const handleSubmit = (event) => {
    console.log('submit', event);
    event.preventDefault();
//    const data = new FormData(event.target)
    handleGuardar();

  }

  useEffect(() => {
    console.log('errores cambio', errores);
    let errorFormulario = false;
    errores.map(error => {
      if(error.error==true) {
        errorFormulario =true;
      }

    });
    setdatosCompletos(errorFormulario);

    
    
  }, [errores])

  useEffect(() => {
    handleFormValidation('nombre', validacion('text', nombre, 1));
    handleFormValidation('apellidoPaterno', validacion('text', apellidoPaterno, 1));
    handleFormValidation('apellidoMaterno', validacion('text', apellidoMaterno, 1));
    handleFormValidation('direccion', validacion('text', direccion, 1));
    handleFormValidation('idMunicipio', validacion('number', idMunicipio, 1));
    handleFormValidation('seccion', validacion('number', idSeccion, 1));
  
  }, [nombre, apellidoPaterno, apellidoMaterno, direccion, idMunicipio, idSeccion])
  
  

  return (
    <div>
      <Dialog  
      maxWidth='sm'
        fullWidth
        open={modalAgregarMilitante} onClose={handleClose}>
      <form onSubmit={handleSubmit}>

        <DialogTitle>Agregar Militante</DialogTitle>
        <DialogContent>
        <Box style={{display:'flex', flexDirection:'column'}}>
        {/* <div>{JSON.stringify(errores)}</div> */}
          <Box
            style={{
              display:'flex',
              flexDirection:'row'
            }}
          >
              <TextField
                  autoFocus
                  margin="dense"
                  id="Nombre"
                  name='Nombre'
                  label="Nombre"
                  type="text"
                  required
                  fullWidth
                  variant="standard"
                  onChange={(e) => {  setNombre(e.target.value.toUpperCase()); }}
                  value={nombre}
                />
              <TextField
                  autoFocus
                  margin="dense"
                  id="ApellidoPaterno"
                  label="A. Paterno"
                  type="text"
                  required
                  fullWidth
                  variant="standard"
                  onChange={(e) => {  setApellidoPaterno(e.target.value.toUpperCase()); }}
                  value={apellidoPaterno}
                />
              <TextField
                  autoFocus
                  margin="dense"
                  id="ApellidoMaterno"
                  label="A. Materno"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {  setApellidoMaterno(e.target.value.toUpperCase()); }}
                  value={apellidoMaterno}
                />
            </Box>
            <Box
              style={{
                display:'flex', 
                flexDirection:'row'
              }}
            >
                <TextField
                    autoFocus
                    margin="dense"
                    id="Calle"
                    label="Calle"
                    required
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {  setCalle(e.target.value.toUpperCase()); }}
                    value={calle}
                    style={{
                      display:'flex',
                      flex:70
                    }}
                  />
                <TextField
                    autoFocus
                    margin="dense"
                    id="numeroExterior"
                    label="Núm. Ext."
                    required
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {  setNumeroExterior(e.target.value.toUpperCase()); }}
                    value={numeroExterior}
                    style={{
                      display:'flex',
                      flex:30,
                      marginLeft:5
                    }}
                  />
                <TextField
                    autoFocus
                    margin="dense"
                    id="numeroInterior"
                    label="Núm. Int."
                    required={false}
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {  setNumeroInterior(e.target.value.toUpperCase()); }}
                    value={numeroInterior}
                    style={{
                      display:'flex',
                      flex:30,
                      marginLeft:5
                    }}
                  />

            </Box>
            <Box
              style={{
                display:'flex', 
                flexDirection:'row'
              }}
            >

            <TextField
                    autoFocus
                    margin="dense"
                    id="colonia"
                    label="Colonia"
                    required
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {  setColonia(e.target.value.toUpperCase()); }}
                    value={colonia}
                    style={{
                      display:'flex',
                      flex:60,
                      marginLeft:5
                    }}
                  />
                <FormControl 
                  style={{
                    display:'flex',
                    flex:30,
                    marginBottom:4,
                    marginLeft:4
                  }}
                size='small' variant="standard" sx={{ mt: 0,  marginLeft:0 }}>

              <InputLabel id="lblDivision">Municipio</InputLabel>
              <Select
                style={{
                  display:'flex',
                  flex:40
                }}
                required
                onChange={(e) => {setIdMunicipio(e.target.value)}}
              >

                { municipios?.map((municipio) => (
                <MenuItem value={municipio.Municipios_IdMunicipio}>{municipio.Municipios_Nombre}</MenuItem>
              ))}
              </Select>
              </FormControl>
              </Box>
            <FormControl size='small' variant="standard" sx={{ mt: 0,  marginLeft:0 }}>
              <InputLabel id="lblDivision">Seccion</InputLabel>
              <Select
              required
                onChange={(e) => {setIdSeccion(e.target.value)}}
              >

                { secciones?.map((seccion) => (
                <MenuItem value={seccion.Secciones_IdSeccion}>{seccion.Secciones_Seccion}</MenuItem>
              ))}
              </Select>
            </FormControl>
          </Box>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button type="submit">Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
      <Snackbar open={openSuccess} onClose={handleCloseSuccess} autoHideDuration={3000} >
        <Alert  severity="success" sx={{ width: '100%' }}>
            Registro guardado!
        </Alert>
      </Snackbar>
    </div>
  );
}
