import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import portada from '../assets/2.png'
import unete from '../assets/unete.png'
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.gruporaosari.com/">
        Grupo Raosari
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();
export default function LoginScreen() {
  const {logueado, onLogin} =useContext(AuthContext);
  console.log(logueado);
//  const {onLogin, usuario, logueado} = React.useContext(AuthContext);
//  console.log(logueado)
  const handleSubmit = (event) => {
    console.log('botton de submit')
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    onLogin(data.get('usuario'), data.get('password'));
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <form component="form" onSubmit={handleSubmit}>
        <div 
          style={{
            display:'flex',
            position:'relative',
            flexDirection:'row',
            justifyContent:'center',
            //backgroundImage:`url(${portada})`,
            backgroundColor:'white',
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            width:'100vw',
            height:'100vh'
          }}  
        >
          <div 
            style={{
              position:'absolute',
              backgroundColor:'transparent',
              display:'flex',
              flex:1,
              flexDirection:'column',
              width:'30vw',
              height:'100vh',
              justifyContent:'space-around',
              marginTop:0,
              borderWidth:1,
              borderColor:'red'            
            }}
          >
            {/* <div  
              style={{
                display:'flex',
                flex:20,
                // flex:1,
                flexDirection:'row',
                justifyContent:'flex-start'
              }}
            >
              <img  
                src={require("../assets/unete.png") } 
                style={{
                  width:300,
                  height:100
                }}
              />

            </div> */}
            <div
              style={{
                display:'flex',
                flex:20,
                flexDirection:'column',
                marginLeft:60,
                marginRight:60,
                backgroundColor: 'white',
                opacity:1,
                borderRadius:20,
                // height:'40vw', 
                justifyContent:'center'
                // marginBottom:50
              }}
            >
              <div 
                style={{
                  display:'flex',
                  flexDirection:'column',
                  marginLeft:20,
                  marginRight:20,
                  marginTop:20,
                  backgroundColor:'lightgray',
                  alignItems:'center',
                  borderRadius:20,
                  padding:20
                  
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'green', marginTop:6 }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Inicio de Sesión
              </Typography>

                  <TextField
                    style={{
                      color:'white'
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="usuario"
                    label="Usuario"
                    name="usuario"
                    autoComplete=""
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Ingresar
                  </Button>
                </div>
            </div>
            {/* <div
              style={{
                display:'flex',
                flex:20,

                flexDirection:'row',
                justifyContent:'center',
                marginBottom:20,
                marginTop:10
                // alignItems:'flex-'
              }}
            > */}
                            {/* <img  
                src={require("../assets/logo_pri.jpeg") } 
                style={{
                  width:150,
                  height:150
                }}
              /> */}

            {/* </div> */}
            {/* <div
              style={{
                display:'flex',
                flex:20,

                flexDirection:'row',
                justifyContent:'center',
                marginBottom:20,
                marginTop:10
                // alignItems:'flex-'
              }}
            >
                           <img  
                src={require("../assets/unidos.png") } 
                style={{
                  width:150,
                  height:75
                }}
              />

            </div> */}
          </div>
        </div>   
        </form>       
          {/* <div
            style={{backgroundColor:'red',
            display:'flex',
            flexDirection:'row',
            position:'absolute',
          }}
            item xs={10} sm={8} md={4} 
          >
            <div
            style={{
              // width:150,
              display:'flex',
              flex:'1',
              flexDirection:'row',
              justifyContent:'flex-end',
              backgroundColor:'blue'
          }}
            >
              <img  
                src={require("../assets/unete.png") } 
                style={{
                  width:150,
                  height:100
                }}
              />
            </div>
              <div
                sx={{
                  my: 4,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor:'red',
                  height:'60vh'
                  // height:'60vh'
                }}
              >

              <Avatar sx={{ m: 1, bgcolor: 'green', marginTop:6 }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Inicio de Sesión
              </Typography>
              <div component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="usuario"
                    label="Usuario"
                    name="usuario"
                    autoComplete=""
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                  {/* <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Ingresar
                  </Button>
                  <Copyright sx={{ mt: 5 }} />
                </div>
              </div>
            </div> } */}
         
    </ThemeProvider>
  );
}