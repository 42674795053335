import { Fade } from '@mui/material';
import React, { useEffect, useState } from 'react'

export const OpinionesSimpatizante = ({idSimpatizante, actualizaOpiniones}) => {
    const [opiniones, setOpiniones] = useState([]);
console.log('id simpatizante opinion', idSimpatizante, actualizaOpiniones);

    useEffect(() => {
        obtenerOpiniones();
    }, [idSimpatizante, actualizaOpiniones])
    
    const obtenerOpiniones = () => {
        fetch('http://mariolegarreta.ddns.net/decidechih/servicios/simpatizantes.php?funcion=simpatizanteOpiniones&idSimpatizante='+idSimpatizante)
        .then(response => response.json())
        .then(datos => {
            console.log('opiniones de api', datos)
            setOpiniones(datos);
        })
        .catch(error => {
            console.error('Ocurrio un error', error);
        })
    }

  return (
    <div
        style={{
            display:'flex',
            justifyContent:'flex-start',
            flexDirection:'column',
            marginTop:10,
            marginLeft:5,
            marginRight:5,
            fontSize:11,
            textAlign:'left',
            width:'100%'
        }}
    >
        {
            opiniones?.map(opinion => (
                <div
                style={{
                    backgroundColor:'lightgray',
                    borderRadius:10,
                    opacity:1,
                    padding:5,
                    marginTop:5
                }}
        
                ><>
                    <div
                        style={{
                            fontSize:12,
                            fontWeight:'bold',
                            color:'green',
                            borderRadius:10
                        }}
                    >
                        {opinion.Usuarios_Nombre+' '+opinion.Usuarios_ApellidoPaterno}
                    </div>
                    <div
                        style={{
                            fontSize:11,
                            fontStyle:'italic',
                            color:'black'
                        }}
                    >
                        {opinion.Fecha1}
                    </div>
                    <div
                        style={{
                            fontSize:11,
                            //fontStyle:'italic',
                            color:'black'
                        }}
                    >
                        {opinion.Opinion}
                    </div>
                    </>
                </div>                    
            ))
        }
    </div>
  )
}
