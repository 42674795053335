import React, { useContext, useEffect } from 'react'
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { SimpatizantesContext } from './SimpatizantesMunicipio';



export const CardConteoSimpatizantesMpio = ({municipio}) => {
    const {municipioSeleccionado, setMunicipioSeleccionado} = useContext(SimpatizantesContext)
//    console.log('municipio recibido', municipio)


const handleClickMunicipio = (valor) => {
   //console.log(valor);
    setMunicipioSeleccionado(valor);
}

  return (
    <div
        onClick={(e) => { handleClickMunicipio(municipio) }}
        style={{
            backgroundColor:'white',
            color:'black',
            borderRadius:5,
            margin:5,
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            marginTop:5,
            padding:10
        }}
    >
        <div
            style={{
                //marginTop:20,
            }}
        >
            {municipio.Municipios_Nombre}
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-around'
                
                
        
            }}
        >
            <div
                style={{
                    marginLeft:10,
                    justifyContent:'right',
                    backgroundColor:'transparent'
                }}
            >
                <Badge
                    max={3000000}
                    style={{
                        fontSize:20
                    }}
                fontSize='15' badgeContent={municipio.LN} color="secondary">
                <HowToVoteIcon fontSize='10' color="action" />
                </Badge>

            </div>
            <div
                style={{
                    marginLeft:30
                }}
            >
                <Badge
                    style={{
                        fontSize:20,
                        marginLeft:10
                    }}
                fontSize='15' max={3000000} badgeContent={municipio.Simpatizantes} color="error">
                <ThumbUpIcon fontSize='10' color="action" />
                </Badge>

            </div>
        </div>  
    </div>
  )
}
