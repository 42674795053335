import logo from './logo.svg';
import './App.css';
import LoginScreen from './screens/LoginScreen';
import React, { createContext, useContext, useState } from 'react';
import { Children } from 'react';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { HomeScreen } from './screens/HomeScreen';
import { ThemeProvider, useTheme } from '@mui/material';
import { themeOptions } from './theme/theme';
import { BrowserRouter } from "react-router-dom";
import AppBarMenu from './components/AppBarMenu';

function App() {
  const {logueado, onLogin} =useContext(AuthContext);
  const theme = useTheme();

  if(!logueado) {
    return(
      <BrowserRouter>
    <div className="App">
      <LoginScreen />
    </div>
    </BrowserRouter>
    )
  }
  
  return (
    <BrowserRouter>
    <ThemeProvider theme={theme}>
    <div className="App">
      <AppBarMenu />
    </div>
    </ThemeProvider>    
    </BrowserRouter>
  );
}

export default App;
