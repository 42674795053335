import React, { useContext } from 'react'
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Fade } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import AgregarMilitante from './AgregarMilitante';

export const OpcionesMilitantes = () => {
    const {modalAgregarMilitante, setModalAgregarMilitante} = useContext(AuthContext);
  return (
    <>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center'
        }}
    >
        <Button variant="contained"
            elevation={4}
            onClick={(e)=>{ setModalAgregarMilitante(true) }}
            style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                color:'white',
                background:'black',
                borderRadius:15
    
            }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'center',
                    color:'white'
        
                }}
            >
                <PersonAddIcon style={{color:'white', fontSize:14, marginRight:5}} />  
                Agregar Militante
            </div>
        </Button>
    </div>
    <AgregarMilitante />
    </>
  )
}
