import React, { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Fade, Input, TextField } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import AgregarUsuario from './AgregarUsuario';

export const OpcionesComite = () => {
    const {modalAgregarSimpatizante, setModalAgregarSimpatizante, filtroIntegrantes, setFiltroIntegrantes} = useContext(AuthContext);

  return (
    <>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center'
        }}
    >
        <TextField
          id="filled-search"
          label="Buscar"
          type="search"
          size='small'
          variant="filled"
          value={filtroIntegrantes}
          onChange={(e) => { setFiltroIntegrantes(e.target.value)}}
          style={{
            backgroundColor:'white',
            borderRadius:10,
            marginRight:20
          }}
        />
            
        <Button variant="contained"
            elevation={4}
            onClick={(e)=>{ setModalAgregarSimpatizante(true) }}
            style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                color:'white',
                background:'black',
                borderRadius:15
    
            }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'center',
                    color:'white'
        
                }}
            >
                <PersonAddIcon style={{color:'white', fontSize:14, marginRight:5}} />  
                Agregar Integrante del Comite
            </div>
        </Button>
    </div>
    </>
  )
}
