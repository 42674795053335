import React, { useContext } from 'react'
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Fade } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import AgregarUsuario from './AgregarUsuario';
import AgregarSimpatizante from './AgregarSimpatizante';
import { ListadoNominalEstado } from './ListadoNominalEstado';
import { ConteoSimpatizantesTotal } from './ConteoSimpatizantesTotal';

export const OpcionesSimpatizantes = () => {
    const {modalAgregarSimpatizante, setModalAgregarSimpatizante} = useContext(AuthContext);
  return (
    <>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center'
        }}
    >
        <ListadoNominalEstado />
        <ConteoSimpatizantesTotal tamFont='15' etiqueta="Simpatizantes" />
        <Button variant="contained"
            elevation={4}
            onClick={(e)=>{ setModalAgregarSimpatizante(true) }}
            style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                color:'white',
                background:'black',
                borderRadius:15
    
            }}
        >

            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'center',
                    color:'white'
        
                }}
            >
                <PersonAddIcon style={{color:'white', fontSize:14, marginRight:5}} />  
                Agregar Simpatizante
            </div>
        </Button>
    </div>
    <AgregarSimpatizante />
    </>
  )
}
