import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TableChart from '@mui/icons-material/TableChart';
import { useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import Logout from '@mui/icons-material/Logout';
import Home from '@mui/icons-material/Home';
import World from '@mui/icons-material/Public';
import User from '@mui/icons-material/Person';
import Usuarios from '@mui/icons-material/Group';
import Group from '@mui/icons-material/Group';
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link, Routes, Route } from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';
import { Home1Screen } from '../screens/Home1Screen';
import { ResultadosScreen } from '../screens/ResultadosScreen';
import { Filtros } from './Filtros';
import { UsuariosScreen } from '../screens/UsuariosScreen';
import { OpcionesUsuarios } from './OpcionesUsuarios';
import { Button, Fade } from '@mui/material';
import { SimpatizantesScreen } from '../screens/SimpatizantesScreen';
import { OpcionesSimpatizantes } from './OpcionesSimpatizantes';
import { IntegrantesComite } from '../screens/IntegrantesComite';
import { OpcionesComite } from './OpcionesComite';
import { MilitantesScreen } from '../screens/MilitantesScreen';
import { OpcionesMilitantes } from './OpcionesMilitantes';
import Candado from '@mui/icons-material/Https';
import CambiaPassword from './CambiaPassword';
const logo = require('../assets/logo_sinergia3.png');

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const {usuario, logueado, setLogueado, mostrarFiltros, mostrarOpcionesUsuario, mostrarOpcionesSimpatizantes, mostrarOpcionesComite, mostrarOpcionesMilitantes,
    setModalCambiaPassword} = React.useContext(AuthContext);
  console.log('logueado',logueado);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [opcion, setOpcion] = useState();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/");
  }, [])
  
  const cambioPassword = () => {
    console.log('abriendo modal desde funcion')
    setModalCambiaPassword(true);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection:'row', flex:1 }}>
      <CssBaseline />
      <AppBar style={{
        backgroundColor:'#FFB0D1'
      }} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box style={{display:'flex', flex:1, flexDirection:'row', justifyContent:'space-between'}}>
              <Box 
                
                style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'right',
                flex:85,
                
              }}>
                { mostrarFiltros===true &&
                  // <Filtros />
                  <></>
                }
                { mostrarOpcionesUsuario===true &&
                    <Fade>
                  <OpcionesUsuarios />
                  </Fade>
                }
                { mostrarOpcionesSimpatizantes===true &&
                  <OpcionesSimpatizantes />
                }
                { mostrarOpcionesComite===true &&
                  <OpcionesComite />
                }
                { mostrarOpcionesMilitantes===true &&
                  <OpcionesMilitantes />
                }
                </Box>
                <Box
                  style={{
                    display:'flex',
                    flex:40,
                    justifyContent:'right'
                  }}
                >
                <div
                  style={{
                    backgroundColor:'transparent',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center'
                  }}
                >
                  <div
                    style={{
                      fontSize:12,
                      backgroundColor:'transparent',
                      color:'white',
                      display:'flex',
                      flexWrap:'nowrap',
                      flexDirection:'row',
                      justifyContent:'center',
                      alignItems:'center'
                    }}
                  >
                    <User style={{color:'white', fontSize:14}} />{usuario.Usuarios_Nombre+' '+usuario.Usuarios_ApellidoPaterno}
                      <Candado onClick={cambioPassword} style={{color:'white', fontSize:16, marginLeft:5}} />
                      <CambiaPassword />
                  </div>
                </div>
                {/* <img 
                style={{
                  borderStyle:'none',
                  height:50
                }}
              src={logo} /> */}
                </Box>

            </Box>
          </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        
        {
          usuario.Usuarios_IdTipoUsuario==1 && (
        <>
        {/* <List key="0">
              <ListItem
                onClick={(e) => { navigate("/Home"); } }
                key="home" disablePadding sx={{ display: 'block' }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Home style={{ color: '#E5337D' }} />
                  </ListItemIcon>
                  <ListItemText primary="Inicio" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List><Divider /> */}
            </>
          )
      }
        
        {
          usuario.Usuarios_IdTipoUsuario==1 && (
        <>
        <List key="1">
        <ListItem 
              onClick={(e) => { navigate("/Mapa"); }}
              key="mapa" disablePadding sx={{ display: 'block' }}
             >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <World style={{color:'#E5337D'}} />
                </ListItemIcon>
                <ListItemText primary="Mapa" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        </List>
        </>
          ) }
        <Divider />
        {/* <List key="4">
        <ListItem 
              onClick={(e) => { navigate("/Simpatizantes"); }}
              key="simpatizantes" disablePadding sx={{ display: 'block' }}
             >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ThumbUpAltIcon style={{color:'green'}} />
                </ListItemIcon>
                <ListItemText primary="Red Sinergia" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        </List>
        <Divider />
        {
          usuario.Usuarios_IdTipoUsuario==1 && (
        <>
        <List key="5">
        <ListItem 
              onClick={(e) => { navigate("/IntegrantesComite"); }}
              key="integrantes" disablePadding sx={{ display: 'block' }}
             >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ThumbUpAltIcon style={{color:'green'}} />
                </ListItemIcon>
                <ListItemText primary="PRI Chihuahua" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        </List>
        </>
          ) }
        <Divider /> */}
        {
          usuario.Usuarios_IdTipoUsuario==1 && (
        <>
        {/* <List key="6">
        <ListItem 
              onClick={(e) => { navigate("/Consultas"); }}
              key="militantes" disablePadding sx={{ display: 'block' }}
             >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ThumbUpAltIcon style={{color:'#E5337D'}} />
                </ListItemIcon>
                <ListItemText primary="Consultas" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        </List>
        </>)}
        <Divider />
        {
          usuario.Usuarios_IdTipoUsuario==1 && (
        <>
        <List key="2">
        <ListItem 
              onClick={(e) => { navigate("/Usuarios"); }}
              key="usuarios" disablePadding sx={{ display: 'block' }}
             >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Usuarios style={{color:'#E5337D'}} />
                </ListItemIcon>
                <ListItemText primary="Usuarios" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        </List> */}
        </>)}
        <Divider />
        <List key="3">
          {['Cerrar sesión'].map((text, index) => (
            <ListItem 
            key="cerrar" disablePadding sx={{ display: 'block' }}>
              <ListItemButton
              // onClick={setLogueado(false)}
              onClick={(e) => {console.log('click en cerrar sesion');navigate("/dynamis/"); setLogueado(false)}}
              sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <Logout style={{color:'#E5337D'}} /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box style={{
        backgroundColor:'black',
        display:'flex',
        flexDirection:'column',
        flex:1,
        height:'100vh'

      }} component="main" sx={{ flexGrow: 1, justifyContent:'left' }}>
        <DrawerHeader />
      <Routes>
        <Route exact path="/" element={<HomeScreen />} />
        <Route exact path="/Home" element={<Home1Screen />} />
        <Route exact path="/Mapa" element={<ResultadosScreen />} />
        <Route exact path="/Usuarios" element={<UsuariosScreen />} />
        <Route exact path="/Simpatizantes" element={<SimpatizantesScreen />} />
        <Route exact path="/IntegrantesComite" element={<IntegrantesComite />} />
        <Route exact path="/Consultas" element={<MilitantesScreen />} />
      </Routes>

      </Box>
    </Box>
  );
}
