import { ImageSearch, Input } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext'


export const SubirFotoIntegranteComite = () => {
    const {integranteSeleccionado} = useContext(AuthContext);
    console.log('integrante seleecionado en foto', integranteSeleccionado);
    const uploadInputRef = useRef(null);
    const imgMilitante = useRef(null);
    const {URL} = useContext(AuthContext);
    const [file, setFile] = useState();
    console.log('subir fotografia');
    const {estadoModalFotografiaMilitante, setEstadoModalFotografiaMilitante} = useContext(AuthContext);

const handleClose = () => {
    setEstadoModalFotografiaMilitante(false);
}

const SeleccionImagen = (event) => {
    setFile({file:event.target.files[0]});
    console.log('selecciono imagen',event);
    var reader = new FileReader();
    reader.onload = function(){
        imgMilitante.current.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
};

const SubirImagen = () => {

}

const handleSubmitMilitante = async (e) => {
    e.preventDefault();
    console.log('Se recibio submit');
    var data = new FormData();
    data.append("data", uploadInputRef.current.files[0]);    
    await fetch(URL+"/guardar.imagen.militante.php?idIntegrante="+integranteSeleccionado.IdIntegrante, { 
        method:'POST',
        body:data
    }).then(function (res) {
      if (res.ok) {
      //  alert("Perfect! ");
        integranteSeleccionado.foto="http://mariolegarreta.ddns.net/decidechih/imagenes/militantes/"+integranteSeleccionado.IdIntegrante+".jpg";
        setEstadoModalFotografiaMilitante(false);
      } else if (res.status == 401) {
      //  alert("Oops! ");
      }
    }, function (e) {
      alert("Error submitting form!");
    });
}

  return (
    <div>
    <Dialog
        open={estadoModalFotografiaMilitante}
        onClose={handleClose}
    >
        <DialogTitle>
            <div>
                Subir Fotografía
            </div>
        </DialogTitle>
        <DialogContent>
            <form onSubmit={(e) => { handleSubmitMilitante(e)}}>
            <Button
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
            >Seleccionar Imagen <ImageSearch /></Button>
            <input name="imgMilitante" onChange={event => SeleccionImagen(event) } ref={uploadInputRef} style={{visibility:'hidden'}} accept='.jpg' type="file" />
            <img style={{
                width:200,
                height:'auto'
            }} ref={imgMilitante} src="" />
            </form>
        </DialogContent>
        <DialogActions>
            <input
                onClick={handleSubmitMilitante}
                type="submit"
                variant='outlined'
                value="Guardar"
            />
        </DialogActions>
    </Dialog>
    </div>
  )
}

