import React, { useContext, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import { AuthContext } from '../context/AuthContext';


const defaultProps = {
    center: {
      lat: 28.700772688116892,
      lng: -106.53524198508615
    },
    zoom: 7
  };
  

export const Mapa = () => {
    const {
      ambito, 
      anio, 
      setAnio,
      secciones,
      URL, 
      opcionesCapas1, 
      setMostrarDatosCapa, 
      mostrarDatosCapa, 
      idMunicipioSeleccionado, 
      setIdMunicipioSeleccionado,
      seccionSeleccionada,
      titulo,
      setTitulo,
      setIdMilitanteSeleccionado,
      setIdSimpatizanteSeleccionado,
      aplicarFiltro
    } = useContext(AuthContext);
    const [map, setMap] = useState();
    const [maps, setMaps] = useState();
    const [isMapsLoaded, setIsMapsLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [layerSecciones, setLayerSecciones] = useState(false);    
    const [layerMilitantes, setLayerMilitantes] = useState(false);    
    const [layerSimpatizantes, setlayerSimpatizantes] = useState(false);
    useEffect(() => {
      console.log('cambio la combinacion year ambito', anio, ambito);
      switch (ambito) {
        case '1':
        case '3':
            if(layerSecciones?.url) {
              let URLMapa = 'http://mariolegarreta.ddns.net/decidechih/servicios/kmls/Mapa'+ambito.padStart(3,0)+anio+'.kml?v=2332';
            layerSecciones.url = URLMapa;
            layerSecciones.setMap(map);          
            }
          break;
        case '2':
            layerSecciones.url = 'http://mariolegarreta.ddns.net/decidechih/servicios/kmls/Mapa'+ambito.padStart(3,0)+anio+'.kml?v=23d4335343';
            layerSecciones.setMap(map);          
          break;
        case '7':
        case '9':
            layerSecciones.url = 'http://mariolegarreta.ddns.net/decidechih/servicios/kmls/Mapa'+ambito.padStart(3,0)+anio+'.kml?v=f234c3d2';
            layerSecciones.setMap(map);          
          break;
      
        default:
          break;
      }
      console.log(layerSecciones);    
    }, [aplicarFiltro])

    

    useEffect(() => {
      console.log('cambio opciones capas', opcionesCapas1);
      const d = new Date();
          const seconds = Math.round(d.getTime() / 1000);
      if(layerMilitantes?.setMap) {
       // console.log('cambiando url a '+'http://raosari.dyndns.info:8080/decidechih/servicios/kmls/generar.capa.militantes.php?v='+seconds+'&anio='+anio+'&seccion='+seccionSeleccionada)
      layerMilitantes.url = 'https://codigobyte.com.mx/servicios/generar.capa.centros.salud.php?v='+seconds+'&anio='+anio+'&seccion='+seccionSeleccionada
      layerMilitantes.setMap(null);
      layerSimpatizantes.url = 'http://mariolegarreta.ddns.net/decidechih/servicios/kmls/generar.capa.simpatizantes.php?v='+seconds+'&anio='+anio+'&seccion='+seccionSeleccionada
      layerSimpatizantes.setMap(null);
      
      opcionesCapas1?.map(capa => {
        if(capa.value==5) {
          layerMilitantes.setMap(map);
        }
        if(capa.value==4) {
          layerSimpatizantes.setMap(map);
        }
      
      })
    }
      if(layerSimpatizantes?.setMap) {
        console.log('cambiando url a '+'http://raosari.dyndns.info:8080/decidechih/servicios/kmls/generar.capa.militantes.php?v='+seconds+'&anio='+anio+'&seccion='+seccionSeleccionada)
      layerSimpatizantes.url = 'http://mariolegarreta.ddns.net/decidechih/servicios/kmls/generar.capa.simpatizantes.php?v='+seconds+'&anio='+anio+'&seccion='+seccionSeleccionada
      layerSimpatizantes.setMap(null);
      
      opcionesCapas1?.map(capa => {
        if(capa.value==4) {
          layerSimpatizantes.setMap(map);
        }
      
      })
    }
    
    }, [aplicarFiltro])
    


    const loadMap = (map, maps) => {
        setMap(map);
        setMaps(maps);
        const d = new Date();
        const seconds = Math.round(d.getTime() / 1000);
        const kmlLayerSecciones = new maps.KmlLayer({
            url: 'http://raosari.dyndns.info:8080/decidechih/servicios/kmls/generar.capa.php?v=202203211403&anio='+anio,
            suppressInfoWindows: true,
            draggable: true,
            zIndex:2000,
            map:map
        });
        setLayerSecciones(kmlLayerSecciones);
        const kmlLayerMilitantes = new maps.KmlLayer({
            url: 'https://codigobyte.com.mx/servicios/generar.capa.centros.salud.php?v='+seconds+'&anio='+anio+'&seccion='+seccionSeleccionada,
            suppressInfoWindows: true,
            draggable: true,
            zIndex:3000,
            map:map
        });
        kmlLayerMilitantes.addListener('click', function(event) {
          setMostrarDatosCapa('militante');
          setIdMilitanteSeleccionado(event);
          console.log(event);
        })
        const kmlLayerSimpatizantes = new maps.KmlLayer({
            url: 'https://codigobyte.com.mx/servicios/generar.capa.consultas.php?v='+seconds+'&anio='+anio+'&seccion='+seccionSeleccionada,
            suppressInfoWindows: true,
            draggable: true,
            zIndex:3000,
            map:map
        });
        kmlLayerSimpatizantes.addListener('click', function(event) {
          setMostrarDatosCapa('simpatizante');
          setIdSimpatizanteSeleccionado(event);
          console.log(event);
        })
        kmlLayerSecciones.addListener('click', function(event) {
          setMostrarDatosCapa('municipio');
          setIdMunicipioSeleccionado(event.featureData.id);
          setTitulo(event.featureData.name);
          console.log(event);
        })
        setLayerMilitantes(kmlLayerMilitantes);
        setlayerSimpatizantes(kmlLayerSimpatizantes);

        setIsLoaded(true);
        setIsMapsLoaded(true);
      };
    

  return (
    <div 
        style={{ 
            height: '80vh', width: '40vw',
            margin:30,
             borderRadius:20 
        }}
    >
    <GoogleMapReact 
      bootstrapURLKeys={{ key: "AIzaSyBW_XR1RMYZ6NZlqdamgkpq0YqclLcYeOk", }}
      
      //ref={myRef}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      draggable={true}
      //year={year.value}
      //seccionseleccionada = {seccionseleccionada}
      onDragend= {(evt) => { }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
          //mapRef.current = map;
         loadMap(map, maps);
          const d = new Date();
          const seconds = Math.round(d.getTime() / 1000);
          return(<b>este es un objeto dentro de una funcion</b>)

  
      }}
      onChange={({map, maps}) => {
          //console.log(year.year.value)
          
          //loadMap1(map,maps,year.year.value);
      }}
    >

    </GoogleMapReact>
  </div>
  )
}
