import React, { useContext, useEffect, useState } from 'react'
import CountUp from 'react-countup';

import { AuthContext } from '../context/AuthContext';
export const ConteoMilitantes = () => {
    const {
        mostrarFiltros, 
        setMostrarFiltros, 
        mostrarDatosCapa,
        idMunicipioSeleccionado, 
        setIdMunicipioSeleccionado,
        URL} = useContext(AuthContext); 
    const [integrantes, setIntegrantes] = useState([]);
    useEffect(() => {
        obtenerConteoMilitantes()    
    }, [idMunicipioSeleccionado])

    const obtenerConteoMilitantes = () => {
        fetch(URL+'/simpatizantes.php?funcion=conteoMilitantesMunicipio&idPoligono='+idMunicipioSeleccionado)
        .then(response => response.json())
        .then(data => {
            setIntegrantes(data);
            console.log('Datos de conteo militantes', data);
        })

    }

  return (
    <div
        style={{
            margin:20,
            marginTop:30,
            backgroundColor:'white',
            opacity:.7,
            borderRadius:10,
            color:'black'
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                margin:10,
                fontSize:17
            }}
        >
            <span
                style={{
                    fontSize:17
                }}
            >Militantes</span> 
            {
                integrantes?.length && 
                <span
                    style={{fontSize:25}}
                >
                    <CountUp end={integrantes[0].Militantes} />
                    
                </span>
            }
        </div>
    </div>
  )
}
