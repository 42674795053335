import React, { createContext, useContext, useEffect, useState } from 'react'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { AuthContext } from '../context/AuthContext';
import { esES } from '@mui/x-data-grid';
import AgregarSimpatizante from '../components/AgregarSimpatizante';
import { ListadoNominalEstado } from '../components/ListadoNominalEstado';
import { SimpatizantesMunicipio } from '../components/SimpatizantesMunicipio';
import fondo from '../assets/simpatizantes.jpeg'
import { SubirFotoIntegranteComite } from '../components/SubirFotoIntegranteComite';
import { OpinionesSimpatizante } from '../components/OpinionesSimpatizante';
import AddCommentIcon from '@mui/icons-material/AddComment';
import avatar from '../assets/avatar.png'
import AgregaOpinion from '../components/AgregaOpinion';
import { Fade } from '@mui/material';
const columns = [
  { field: 'Simpatizantes_ApellidoPaterno', headerName: 'A. Paterno', flex: 10 },
  { field: 'Simpatizantes_ApellidoMaterno', headerName: 'A. Materno', flex: 10 },
  { field: 'Simpatizantes_Nombre', headerName: 'Nombre', flex: 15 },
  { field: 'Simpatizantes_Direccion', headerName: 'Dirección', flex: 10 },
  { field: 'Municipios_Nombre', headerName: 'Municipio', flex: 10 },
  { field: 'Capturo', headerName: 'Capturó', flex: 10 },
  { field: 'Secciones_Seccion', hide:true,  headerName: 'Seccion', flex: 1 },
];

export const estadoModalSimpatizante = createContext();

export const SimpatizantesScreen = () => {
    const {usuario, URL,mostrarOpcionesSimpatizantes, setMostrarOpcionesSimpatizantes,modalAgregarSimpatizante, setModalAgregarSimpatizante, idSimpatizanteEdicion, setIdSimpatizanteEdicion, setModalAgregaOpinion} = useContext(AuthContext);
    const [simpatizantes, setSimpatizantes] = useState([]);
    const [simpatizanteSeleccionado, setsimpatizanteSeleccionado] = useState();
    const [open, setOpen] = useState(false);
    const [actualizaOpiniones, setActualizaOpiniones] = useState(0);


    useEffect(() => {
        setMostrarOpcionesSimpatizantes(true);
        ObtenerSimpatizantes();
        return () => {
            setMostrarOpcionesSimpatizantes(false);
        }
    
    
    }, [])

    const handleAgregaOpinion = ()  => {
        setModalAgregaOpinion(true)
    }

    const ObtenerSimpatizantes = () => {
        fetch(URL+'/simpatizantes.php?funcion=simpatizantes&idUsuario='+usuario.Usuarios_IdUsuario)
        .then(response => response.json())
        .then(data => {
            console.log('simpatizantes', simpatizantes);
            setSimpatizantes(data);
        })
        .catch(error => {
            console.log('Ocurrio un error', error);
        })
    }

    const abrirPdf = () => {
        console.log('abrir pdf');
        window.open('http://mariolegarreta.ddns.net/dynamis/Ficha_Antonio.pdf');
    }

    const editarSimpatizante = (params, event) => {
        console.log('datos en edicion simpatizante', params, event)
        setIdSimpatizanteEdicion(parseInt(params.id));

    }

    return (
    <estadoModalSimpatizante.Provider value={ObtenerSimpatizantes} >
    <>
        <div 
            style={{ 
                height: '85%', 
                width: '96%',
                backgroundColor:'black',
                marginLeft:20,
                marginRight:0,
                marginTop:20,
                borderRadius:20,
                display:'flex',
                flexDirection:'row',
                backgroundImage:`url(${fondo})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                backgroundPositionY:0,
                backgroundColor:'red',
                backgroundPosition:'center',
        
            }}>
            <div
            
             style={{
                display:'flex',
                flex:40
               // flex:45
             }}
            >
                <SimpatizantesMunicipio />
            </div>    
            <div
                style={{
                    display:'flex',
                    flex:60,
                    flexDirection:'column',
                    backgroundColor:'white',
                    opacity:.75,
                    borderRadius:5,
                    marginTop:5,
                    marginRight:5

                }}
            >
                <DataGrid 
                     onCellDoubleClick={(params, event) => {
                        if (!event.ctrlKey) {
                          event.defaultMuiPrevented = true;
                          editarSimpatizante(params, event);
                        }
                      }}
                      onCellClick={(params, event) => {
                        console.log(params, event);
                        setsimpatizanteSeleccionado(params.row)
                      }}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    style={{
                        fontSize:11,
                        borderRadius:20
                    }}
                    density='compact' rows={simpatizantes} columns={columns} 
                    
                />
            </div>
            {
                simpatizanteSeleccionado?.Simpatizantes_IdSimpatizante && (
            <div
                style={{
                    display:'flex',
                    flex:20,
                    flexDirection:'column',
                    backgroundColor:'white',  
                    opacity:.75,
                    borderRadius:5,
                    marginTop:5

                }}
            >
                <div
                    style={{
                        display:'flex',
                        flex:30,
                        flexDirection:'row',
                        justifyContent:'center'
                    }}
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            width:'90%',
                            fontSize:11
                        }} >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'row',
                            flex:1,
                            width:'95%',
                           // height:'25vh',
                            flexWrap:'nowrap',
                            backgroundColor:'transparent', 
                            justifyContent:'center',
                            alignItems:'center',
                            //transform:"rotate(-7deg)",
                           // position:'absolute',
                            backgroundImage: 'url('+simpatizanteSeleccionado?.Simpatizantes_Foto+')' ,
                            backgroundRepeat:'no-repeat',
                            backgroundPosition:'center',
                            backgroundSize:'contain',
                            marginTop:'1vh',
                            borderRadius:10,
                            backgroundColor:'white'
                            
                        }}
                    >

                    </div>
                    <div>{simpatizanteSeleccionado?.Simpatizantes_FechaNacimiento}</div>
                    <div>{simpatizanteSeleccionado?.Municipios_Nombre}</div>
                    <div>Sección {simpatizanteSeleccionado?.Secciones_Seccion}</div>
                    {
                     usuario.Usuarios_IdTipoUsuario==1 && (
                    <div onClick={(e) => { handleAgregaOpinion() } } style={{display:'flex', flexDirecion:'row', justifyContent:'flex-start', alignItems:'center', color:'green'}}><AddCommentIcon  style={{color:'green', fontSize:15}} />  Agregar Opinión</div>
                     )}
                    </div>

                </div>
                {
                     usuario.Usuarios_IdTipoUsuario==1 && (
                <div
                    style={{
                        display:'flex',
                        flex:70,
                        overflowY:'scroll',
                        transition: 'width 0.5s, height 0.5s, opacity 0.5s 0.5s'
                    }}
                >
                    <OpinionesSimpatizante actualizaOpiniones={actualizaOpiniones} idSimpatizante={simpatizanteSeleccionado?.Simpatizantes_IdSimpatizante}  />
                </div>
                )}
            </div>
            )}
        </div>
        <AgregarSimpatizante />
        <AgregaOpinion actualizaOpiniones={actualizaOpiniones} setActualizaOpiniones={setActualizaOpiniones} simpatizanteSeleccionado={simpatizanteSeleccionado} />
    </>
    </estadoModalSimpatizante.Provider>
    )
}
