import React, { useContext, useEffect, useState } from 'react'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { AuthContext } from '../context/AuthContext';



const columns = [
  { field: 'Usuarios_Username', headerName: 'Username', width: 150 },
  { field: 'NombreCompleto', headerName: 'Nombre', width: 400 },
];

export const UsuariosScreen = () => {
    const {URL,mostrarOpcionesUsuario, setMostrarOpcionesUsuario, obtenerUsuarios, usuarios} = useContext(AuthContext);

    useEffect(() => {
        setMostrarOpcionesUsuario(true);
        obtenerUsuarios();
        return () => {
            setMostrarOpcionesUsuario(false);
        }
    
    
    }, [])
    
    

  return (
    <div 
        style={{ 
            height: '85%', 
            width: '50%',
            background:'white',
            marginLeft:20,
            marginTop:20,
            borderRadius:20
    
    }}>
      <DataGrid rows={usuarios} columns={columns} />
    </div>
  );
}