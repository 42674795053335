import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';



export const ListadoNominalEstado = () => {
const [listadoNominalEstado, setListadoNominalEstado] = useState();

    useEffect(() => {
        obtenerMilitantesEstado();
      }, [])
      
      const obtenerMilitantesEstado =() => {
        fetch('http://mariolegarreta.ddns.net/decidechih/servicios/casillas.php?funcion=listadoNominalEstado')
        .then(response => response.json()
        .then(data => {
            console.log(data);
            //const listadoNominal = parseInt(data);
            setListadoNominalEstado(data);
        }))
    }

    useEffect(() => {
        console.log('Listado Nominal estado', listadoNominalEstado);
    }, [listadoNominalEstado])
    
    
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            borderRadius:10,
            backgroundColor:'white',
            color:'green',
            padding:5,
            alignItems:'center',
            marginRight:30
        }}
    >
        <span>Listado Nóminal</span>
        <span
                style={{fontSize:15, marginLeft:10, fontWeight:'bold'}}
            >
            <CountUp end={listadoNominalEstado} />
                
        </span>

    </div>
  )
}
