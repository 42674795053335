import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
const imagen = require('../assets/tren_chepe_chihuahua.jpg');
const imagen1 = require('../assets/cascada_basaseachi.jpeg');
const imagen3 = require('../assets/edificio_pri.jpeg');
const imagen2 = require('../assets/portada.jpg');
const logo = require('../assets/logo_pri.jpeg');

export const HomeScreen = () => {
      var items = [
          // {
          //     name: "Random Name #1",
          //     description: "Probably the most random thing you have ever seen!",
          //     image:imagen2
          // },
          {
              name: "Random Name #2",
              description: "Hello World!",
              image:imagen
          },
          {
              name: "Random Name #2",
              description: "Hello World!",
              image:imagen1
          },
          {
              name: "Random Name #2",
              description: "Hello World!",
              image:imagen3
          }
      ]
  
      return (
        <></>
          // <Carousel
          //   stopAutoPlayOnHover={false}
          //   animation="fade"
          //   navButtonsAlwaysVisible={true}
          //   duration={3000}
          //   interval={10000}
          // borderColor="white"
          // >
          //     {
          //         items.map( (item, i) => <Item style={{borderColor:'white'}} key={i} item={item} /> )
          //     }
          // </Carousel>
      )
  
  function Item(props)
  {
      return (
          <Paper style={{backgroundColor:'transparent', borderColor:'white'}}>
              {/* <h2>{props.item.name}</h2> */}
              <div
                style={{
                  display:'flex',
                  flex:1,
                  justifyContent:'center',
                  backgroundColor:'transparent',
                  borderColor:'white'
                  
                }}

                
              >
              <img 
                style={{
                  // height:'75vh',
                  //maxHeight:'92vh',
                   width:'100%',
                  height:'auto',
                  // borderRadius:30,
                  borderColor:'white',
                  borderStyle:'none',
                  position:'relative',
                  //objectFit:'cover'

                }}
              src={props.item.image} />
              <div
                style={{
                  position:'absolute',
                  backgroundColor:'red',
                  display:'flex',
                  flex:1,
                  flexDirection:'row',
                  width:'80vw',
                  justifyContent:'right',
                  marginTop:20
                }}  
              >
                <img 
                style={{
                  borderStyle:'none',
                  height:150,
                  position:'absolute',
                  justifyContent:'right'
                }}
              src={logo} />
              </div>

              </div>

          </Paper>
      )
  }
}