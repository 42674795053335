import React, { createContext, useContext, useEffect, useState } from 'react'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { AuthContext } from '../context/AuthContext';
import { esES } from '@mui/x-data-grid';
import AgregarSimpatizante from '../components/AgregarSimpatizante';
import AgregarMilitante from '../components/AgregarMilitante';
import { DatosMilitante } from '../components/DatosMilitante';


const columns = [
  { field: 'Militantes_ApellidoPaterno', headerName: 'A. Paterno', flex: 10 },
  { field: 'Militantes_ApellidoMaterno', headerName: 'A. Materno', flex: 10 },
  { field: 'Militantes_Nombre', headerName: 'Nombre', flex: 10 },
  { field: 'Militantes_Municipio', headerName: 'Municipio', flex: 10 },
  { field: 'Padron_Seccion', headerName: 'Seccion', flex: 10 },
];

export const estadoModalMilitante = createContext();

export const MilitantesScreen = () => {
    const {URL,mostrarOpcionesMilitantes, setMostrarOpcionesMilitantes,modalAgregarSimpatizante} = useContext(AuthContext);
    const [militantes, setMilitantes] = useState([]);
    const [open, setOpen] = useState(false);
    const [cargando, setCargando] = useState(false)


    useEffect(() => {
        setMostrarOpcionesMilitantes(true);
        ObtenerMilitantes();
        return () => {
            setMostrarOpcionesMilitantes(false);
        }
    
    
    }, [])

    const ObtenerMilitantes = () => {
        setCargando(true);
        fetch(URL+'/simpatizantes.php?funcion=militantes2')
        .then(response => response.json())
        .then(data => {
            console.log('militantes', data);
            setMilitantes(data);
            setCargando(false);
        })
        .catch(error => {
            console.log('Ocurrio un error', error);
        })
    }

    if(cargando==false) {
    return (
    <estadoModalMilitante.Provider value={ObtenerMilitantes} >
    <>
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                height:'85vh',
                justifyContent:'space-around'
            }}
        >
            <div 
                style={{ 
                    display:'flex',
                    flex:45,
                    height: '100%', 
                    backgroundColor:'white',
                    marginLeft:20,
                    marginRight:0,
                    marginTop:20,
                    borderRadius:20
                }}>
                <DataGrid 
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    style={{
                        fontSize:11,
                        borderRadius:20
                    }}
                    density='compact' rows={militantes} columns={columns} 
                />
            </div>
            <div
                style={{
                    display:'flex',
                    flex:42,
                    flexDirection:'column', 
                    marginLeft:10,
                    marginTop:20,
                    marginRight:10
                }}
            >
                <DatosMilitante />
            </div>
        </div>
        <AgregarMilitante />
    </>
    </estadoModalMilitante.Provider>
    )
            } else {
                return (
                    <div style={{color:'white'}}>Cargando...</div>
                )
            }
}
