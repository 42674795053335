import React, {useContext, useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AuthContext } from '../context/AuthContext';
import { Input, TextareaAutosize, TextField } from '@mui/material';

export default function CambiaPassword() {
    const {modalCambiaPassword, setModalCambiaPassword, usuario} = useContext(AuthContext);
    console.log('usuario recibido', usuario);
    const [passActual, setPassActual] = useState('');
    const [nuevoPass, setNuevoPass] = useState('');
    const [nuevoPass1, setNuevoPass1] = useState('');
    const [errorPass, setErrorPass] = useState(false);
    const [errorDiferentes, setErrorDiferentes] = useState(false);

    useEffect(() => {
    }, [errorPass])
    

    console.log('modal cambio pass', modalCambiaPassword)

  const handleClose = () => {
    console.log('cerrar modal cambio pass')
    setModalCambiaPassword(false);
  };

  useEffect(() => {
    if(nuevoPass===nuevoPass1) {
      setErrorDiferentes(false);
    } else {
      setErrorDiferentes(true);
    }
  }, [nuevoPass, nuevoPass1])
  
  const validaPassActual = () => {
    if(passActual!=usuario.Usuarios_Password) {
      setErrorPass(true);
    } else {
      setErrorPass(false);
    }
  }

  const handleGuardar =() => {
    console.log('intentando guardar', passActual, usuario.Usuarios_Password);
   if(errorDiferentes==false && errorPass==false && nuevoPass.length>=5 ) { 

    fetch('http://mariolegarreta.ddns.net/decidechih/servicios/usuarios.php?funcion=cambiarPass&idUsuario='+usuario.Usuarios_IdUsuario+'&nuevoPass='+nuevoPass)
    .then(response => {
        setModalCambiaPassword(false);
//        setActualizaOpiniones(actualizaOpiniones+1);
//        ObtenerSimpatizantes();
    })        
    .catch(error => {
        console.error('Ocurrio un error');
    })
  }
}

  return (
    <div style={{
      opacity:.8
    }}>
      <Dialog
        style={{
          opacity:0.8,
          borderRadius:7
        }}
        open={modalCambiaPassword}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cambiar Contraseña"}
          <div
            >
                
            </div>

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form id='frmCambioPassword'>
              <div
                style={{
                  display:'flex',
                  flexDirection:'column'
                }}
              >
                <TextField 
                  onChange={(event) => {console.log('evento',event.target.value); setPassActual(event.target.value)}}
                  onBlur={validaPassActual}
                  required
                  variant='outlined'
                  label="Contraseña actual"
                  type="password"
                  style={{
                    marginTop:10
                  }}
                />
                <div
                  style={{
                    fontSize:12,
                    color:'red',
                    display: errorPass==false ? 'none':'block'
                  
                  }}
                >
                  Contraseña Incorrecta
                </div>
                <TextField 
                  onChange={(event) => {setNuevoPass(event.target.value)}}
                  required
                  variant='outlined'
                  label="Nueva contraseña"
                  type="password"
                  style={{
                    marginTop:10
                  }}
                />
                <TextField 
                  onChange={(event) => {setNuevoPass1(event.target.value)}}
                  required
                  variant='outlined'
                  label="Confirme contraseña"
                  type="password"
                  style={{
                    marginTop:10
                  }}
                />
                <div
                  style={{
                    fontSize:12,
                    color:'red',
                    display: errorDiferentes==false ? 'none':'block'
                  
                  }}
                >
                  Contraseñas Diferentes
                </div>

              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button onClick={handleGuardar} autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}