import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import Simpatizante from '@mui/icons-material/Person2';
import Celular from '@mui/icons-material/PhoneAndroid';
import Pastel from '@mui/icons-material/Cake';
import Mundo from '@mui/icons-material/Public';
import Direccion from '@mui/icons-material/Home';
import Seccion from '@mui/icons-material/HowToVote';
import { CalendarMonth } from '@mui/icons-material';
import dayjs from 'dayjs';
export const DatosSimpatizante = () => {
const {idSimpatizanteSeleccionado, URL} = useContext(AuthContext);
console.log('id simpatizante seleccionado en datos simpatizante')
const [datosSimpatizante, setDatosSimpatizante] = useState([]);
useEffect(() => {
  obtenerSimpatizante();
}, [idSimpatizanteSeleccionado])

const obtenerSimpatizante = () => {
  fetch('https://codigobyte.com.mx/servicios/datos.reporte.php?idReporte='+idSimpatizanteSeleccionado?.featureData?.name)
  .then(response => response.json())
  .then(simpatizante => {
    console.log('datos obtenidos de api', simpatizante);
    setDatosSimpatizante(simpatizante);
  })
  .catch(error => {
    console.error('ocurrio un error', error)
  })
}


//console.log('datos de simpatizante seleccionado', idSimpatizanteSeleccionado);
if(datosSimpatizante?.ProfileName ) {
  return (
    <div
        style={{
          display:'flex',
          width:390,
          flexDirection:'column',
          margin:20,
          marginTop:30,
          backgroundColor:'white',
          opacity:.5,
          borderRadius:10,
          color:'black',
          padding:20,
          alignItems:'flex-start',
          color:'green'
          
        }}    
    >
        {/* <div
          style={{
              display:'flex',
              flexDirection:'row',
              //flex:1,
              width:'350px',
              height:'300px',
              flexWrap:'nowrap',
              backgroundColor:'white', 
              justifyContent:'center',
              alignItems:'center',
              //transform:"rotate(-7deg)",
              // position:'absolute',
   
              marginTop:'1vh',
              borderRadius:10,
              backgroundColor:'white',
              fontSize:12
              
          }}
      >
        <img style={{width:'auto', height:266, maxHeight:266}} src={datosSimpatizante[0]?.Simpatizantes_Foto} />
      </div> */}
      <div 
        style={{
          opacity:1,
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          justifyContent:'center'
        }}
      >
          <div>
             <span
               style={{
                 color:'#2B784A'
               }}
             ></span><span style={{color:'gray', opacity:1, alignItems:'center', backgroundColor:'transparent' , fontSize:12}}>Consulta #{datosSimpatizante?.IdReporte} </span>
          </div>

      </div>
      <div 
        style={{
          opacity:1,
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          justifyContent:'center'
        }}
      >
     
     <div>
       <span style={{display:'flex',color:'#2B784A', justifyContent:'center', alignItems:'center', backgroundColor:'transparent', fontSize:16}} >
        <Simpatizante style={{fontSize:17}} />
       </span>
       </div>
       <span style={{color:'gray', opacity:1, alignItems:'center', backgroundColor:'transparent', fontSize:12}}>{datosSimpatizante?.ProfileName} </span>
     </div>
     <div>
        <span
          style={{
            color:'#2B784A'
          }}
        ><Celular style={{fontSize:17}} /></span><span style={{color:'gray', opacity:1, alignItems:'center', backgroundColor:'transparent' , fontSize:12}}> {datosSimpatizante?.Telefono} </span>
     </div>
     <div>
     <span
          style={{
            color:'#2B784A'
          }}
        >
        <CalendarMonth style={{fontSize:17}} /></span><span style={{color:'gray', opacity:1, alignItems:'center', backgroundColor:'transparent', fontSize:12}}> {dayjs(datosSimpatizante?.Fecha).format('DD/MM/YYYY HH:mm')}</span>
     </div>

    </div>
  )
      } else {
        return (
          <></>
        )
      }
}
