import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'

export const DatosMilitante = () => {
const {idMilitanteSeleccionado, URL} = useContext(AuthContext);
console.log('id militante seleccionado en datos militante')
const [datosMilitante, setDatosMilitante] = useState([]);

useEffect(() => {
  obtenerMilitante();
}, [idMilitanteSeleccionado])

const obtenerMilitante = () => {
  fetch('https://codigobyte.com.mx/servicios/datos.centro.salud.php?idCentroSalud='+idMilitanteSeleccionado?.featureData?.name)
  .then(response => response.json())
  .then(militante => {
    console.log('datos obtenidos de api', militante);
    setDatosMilitante(militante);
  })
  .catch(error => {
    console.error('ocurrio un error', error)
  })
}


console.log('datos centro de salud', idMilitanteSeleccionado);
if(datosMilitante?.nombreUnidad ) {
  return (
    <div
        style={{
          display:'flex',
          flexDirection:'column',
          margin:20,
          marginTop:30,
          backgroundColor:'white',
          opacity:.5,
          borderRadius:10,
          color:'black',
          padding:20,
          alignItems:'flex-start',
          color:'green'
          
        }}    
    >
      <div 
        style={{
          opacity:1
        }}
      >
       {datosMilitante?.nombreUnidad} 
     </div>
     <div>
        {datosMilitante?.observacionesDireccion}
     </div>
     <div>
        {datosMilitante?.municipio}
     </div>
     <div>
        {datosMilitante?.telefono}
     </div>

    </div>
  )
      } else {
        return (
          <></>
        )
      }
}
