import { useEffect, useState } from "react";

const validacion = (tipo, valor, longitudMinima=0) => {
    let error = true;
  //  console.log('tipo', tipo);
   // console.log('longitud', longitudMinima);
   // console.log('valor', valor);
    if(!valor) {
        return true;
    }
    if(tipo==='text') {
        if(longitudMinima>0) {
            if(valor.length>=longitudMinima) {
                return false;
            } else {
                return true;
            }
        }
    }
    if(tipo==='number') {
        if(valor>0) {
            return false;
        } else {
            return true;
        }
    }



    //return error;
}

export default validacion;