import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';



export const ConteoSimpatizantesTotal = ({etiqueta, tamFont}) => {
const [conteoSimpatizantes, setConteoSimpatizantes] = useState();
    useEffect(() => {
        obtenerTotalSimpatizantes();
      }, [])
      
      const obtenerTotalSimpatizantes =() => {
        fetch('http://mariolegarreta.ddns.net/decidechih/servicios/simpatizantes.php?funcion=conteoSimpatizantesEstado')
        .then(response => response.json()
        .then(data => {
            console.log(data);
            //const listadoNominal = parseInt(data);
            setConteoSimpatizantes(data);
        }))
    }

    useEffect(() => {
        console.log('Simpatizantes estado', conteoSimpatizantes);
    }, [conteoSimpatizantes])
    
    
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            borderRadius:10,
            backgroundColor:'white',
            color:'green',
            padding:5,
            alignItems:'center',
            marginRight:30
        }}
    >
        <span>{etiqueta}</span>
        <span
                style={{fontSize:{tamFont}, marginLeft:10, fontWeight:'bold', textAlign:'center'}}
            >
            <CountUp end={conteoSimpatizantes} />
                
        </span>

    </div>
  )
}
