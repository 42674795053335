import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext';
import CountUp from 'react-countup';

export const TablaResultadosPartido = () => {
    const {
        mostrarFiltros, 
        setMostrarFiltros, 
        mostrarDatosCapa,
        idMunicipioSeleccionado, 
        setIdMunicipioSeleccionado,
        anio, datosPartido, setDatosPartido, ambito,
        URL} = useContext(AuthContext); 

    useEffect(() => {
        getResuladosPartido();
    }, [idMunicipioSeleccionado, anio])
    

        const getResuladosPartido = () => {
            fetch(URL+'/casillas.php?funcion=resultadosseccion&casilla='+idMunicipioSeleccionado+'&anio='+anio+'&idambito='+ambito+'&filterslength=0&pagenum=0&pagesize=10&_=1668979512899')
            .then(response => response.json())
            .then(data => {
                setDatosPartido(data);
                console.log('Datos de resultados por partido', data);
            })
            .catch(error => {
                console.log('Ocurrio un error', error)
            })
        }

        const getEncabezado = () => {
            fetch(URL+'/casillas.php?funcion=resultadosseccion&casilla='+idMunicipioSeleccionado+'&anio='+anio+'&idambito=1&filterslength=0&pagenum=0&pagesize=10&_=1668979512899')
            .then(response => response.json())
            .then(data => {
                setDatosPartido(data);
                console.log('Datos de resultados por partido', data);
            })
            .catch(error => {
                console.log('Ocurrio un error', error)
            })

        }
    

if(datosPartido?.tablaDatos?.length){
  return (
    <div
    style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        flexWrap:'nowrap',
        flex:1,
        margin:10,
        marginTop:10,
        backgroundColor:'white',
        opacity:.7,
        borderRadius:10,
        color:'black',
        minHeight:100,
        marginLeft:20,
        padding:0
    }}
    >
        <>
            <div 
                style={{
                    backgroundColor:'green',
                    color:'white',
                    borderTopLeftRadius:10,
                    borderTopRightRadius:10
                }}
            >
                Votos Nulos <CountUp end={datosPartido.nulos} /> 
            </div>
            <div
                style={{
                    padding:10
                }}
            >
        {
            datosPartido?.tablaDatos?.map((resultado) => (
                
                <div key={resultado.Partidos_IdPartido}
                    style={{
                        backgroundColor:'transparent',
                        flexDirection:'row',
                        display:'flex',
                        justifyContent:'flex-start'
                    }}
                >
                    <div
                        style={{
                            display:'flex',
                            justifyContent:'flex-start',
                            flex:80,
                            flexWrap:'nowrap'
                        }}
                    >
                        {resultado.Partidos_Partido}
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flex:30,
                            justifyContent:'flex-end'
                        }}
                    >
                         <CountUp end={resultado.CasillasResultadosDetalle_Votos} />
                    </div>
                </div>
            ))
        }
        </div>
        </>

    </div>
  )
    } else {
        return (
            <div
                style={{
                    backgroundColor:'transparent'
                }}
            >Sin datos</div>
        )
    }
}
