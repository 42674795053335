import React, { useContext, useEffect, useState } from 'react'
import { SimpatizantesContext } from './SimpatizantesMunicipio'


export const SimpatizantesUsuario = () => {
    const {municipioSeleccionado} = useContext(SimpatizantesContext);
    const [simpatizantesMunicipioUsuario, setSimpatizantesMunicipioUsuario] = useState([]);
    console.log('Municipio usuarios', municipioSeleccionado)

    useEffect(() => {
        console.log('cambio el municipio seleccionado', municipioSeleccionado)
        if(municipioSeleccionado?.Municipios_IdMunicipio) {
          obtenerSimpatizantesUsuario();
        }
    }, [municipioSeleccionado])
    
    const obtenerSimpatizantesUsuario = () => {
      fetch('http://mariolegarreta.ddns.net/decidechih/servicios/simpatizantes.php?funcion=conteoSimpatizantesUsuario&idMunicipio='+municipioSeleccionado.Municipios_IdMunicipio)
      .then(response => response.json())    
      .then(data => {
          console.log('simpatizantes mpio usuario', data)
          setSimpatizantesMunicipioUsuario(data);
      })
      .catch(error => {
          console.log('Ocurrio un error', error)
      })

    }

  return (
    <div
      style={{
        display:'flex',
        flexDirection:'column',
        marginTop:5,
        fontSize:11
      }}
    >
      {
        simpatizantesMunicipioUsuario?.map(usuario => (
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              backgroundColor:'white',
              color:'black',
              borderRadius:5,
              marginLeft:5,
              marginRight:5,
              alignItems:'center'
            }}
          >
            <div
              style={{
                display:'flex',
                marginLeft:5,
              }}
            >
              {usuario.Usuario}
            </div>
            <div
              style={{
                display:'flex',
                justifyContent:'center',
                flexDirection:'row',
                marginLeft:5,
                backgroundColor:'green',
                color:'white',
                borderRadius:5,
                paddingLeft:15,
                paddinRight:15,
                marginRight:20,
                width:50,
                marginTop:5,
                marginBottom:5
              }}
            
            >
              {usuario.Simpatizantes}
            </div>
          </div>
        ))
      }

    </div>
  )
}
